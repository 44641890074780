import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import footer1 from './images/footer-1.png';
import footer2 from './images/footer-2.png';
import footer3 from './images/footer-3.png';

const hostName = window.location.hostname;
const year = new Date().getFullYear();

const Footer = props => {
    return (
        <footer>
            <div className={style.logoRow}>
                <div className={props.className}>
                    <div className="row">
                        <div className={'col-xs-12 col-sm-3 ' + style.logoContainer}>
                            <img
                                src={footer1}
                                alt="MedStar Georgetown Center for Wellbeing in School Environments"
                            />
                        </div>
                        <div className={'col-xs-12 col-sm-3 ' + style.logoContainer}>
                            <img src={footer2} alt="MedStar Health" />
                        </div>
                        <div className={'col-xs-12 col-sm-3 ' + style.logoContainer}>
                            <img
                                src={footer3}
                                alt="Georgetown University Center for Child and Human Development"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.contactBg}>
                <div className={props.className}>
                    <div className={style.contactRow}>
                        <span>
                            Website and software developed by{' '}
                            <a
                                href="https://www.3cisd.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                3C Institute
                            </a>
                            . &copy; {year}. All rights reserved.
                        </span>
                        <span className={style.termsAndPrivacy}>
                            <a
                                href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={style.termsLink}
                            >
                                Terms of Use
                            </a>
                            <a
                                href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

Footer.defaultProps = {
    className: 'container',
};

export default Footer;
