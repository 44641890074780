import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import style from './style.css';
import useCourseWrapperMediator from './useCourseWrapperMediator';
import Certificate from './Certificate';
import CourseDescription from './CourseDescription';
import InvokeSubMediator from './InvokeSubMediator';
import FinalTest from './FinalTest';
import Resources from './Resources';
import Tab from './Tab';
import { client } from 'cccisd-apollo';
import Axios from 'cccisd-axios';
import poiseDeploymentIdQuery from './poiseDeploymentId.graphql';
import smartGoalsQuery from './smartGoals.graphql';
import _get from 'lodash/get';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const dummyPoiseData = {
    devTags: {
        poise_p_average: '2',
        poise_o_average: '3',
        poise_i_average: '3.6',
        poise_s_average: '4.6',
        poise_e_average: '1.4',
    },
    deployment: {
        timepoint: '1',
        deploymentId: 3,
    },
};

/* ASSUMPTIONS:
 *  1. POISE assignment has the assignment handle "poise" in the database
 *  2. Main assignment has the assignment handle "main" in the database
 *  3. The repeated POISE deployment to be used in main course has the
 *      deployment handle "poiseParent", and it has the deployment
 *      settings key {"isRepeated": true}
 *  4. The Introduction should be split into at least two surveys (one
 *      before POISE and one after)
 *  5. The Introduction survey right before the POISE deployment should have
 *      the survey setting {"playPoiseAfter": true} at the top level of settings
 *  6. In the Code tab, double check that the values of the POISE survey
 *      are mapped to a 1-5 scale:
 *      1 <- Not at all
 *      2 <- A little
 *      3 <- Moderately
 *      4 <- Mostly
 *      5 <- Almost always
 *  7. The following devTags should be applied to the corresponding metrics
 *      variables using the Code tab:
 *   [
 *    "poise_p_average", "poise_o_average", "poise_i_average", "poise_s_average", "poise_e_average",
 *
 *    "smart_goal_p_1", "smart_goal_p_2", "smart_goal_p_3", "smart_goal_p_4", "smart_goal_p_5",
 *    "smart_goal_o_1", "smart_goal_o_2", "smart_goal_o_3", "smart_goal_o_4", "smart_goal_o_5",
 *    "smart_goal_i_1", "smart_goal_i_2", "smart_goal_i_3", "smart_goal_i_4", "smart_goal_i_5",
 *    "smart_goal_s_1", "smart_goal_s_2", "smart_goal_s_3", "smart_goal_s_4", "smart_goal_s_5",
 *    "smart_goal_e_1", "smart_goal_e_2", "smart_goal_e_3", "smart_goal_e_4", "smart_goal_e_5",
 *
 *    "restful_sleep", "nutritious_food", "exercise", "not_sedentary", "artistic_activities",
 *    "sense_of_purpose", "supportive_people", "time_management", "achieve_goals",
 *    "supervisor_communication", "stimulate_mind", "pursue_interests", "help_others", "life_story",
 *    "principles_values", "satisfying_relationships", "beneficial_feedback", "annoyance_awareness",
 *    "acknowledge_success", "resolve_conflicts", "painful_emotions", "feel_calm", "effective_coping",
 *    "manage_emotions", "enjoy_present
 *   ]
 *  8. In withModules navigation settings JSON (at the assignment level), each module that
 *      corresponds to one of the averages should have a key "devTag" that maps that module
 *      to the metric. For example { "devTag": "poise_p_average" } in the Physical module
 *  9. In the withModules navigation settings JSON (at the assignment level), the Smart Goals
 *      module should have the key/value ` "isSmartGoals": true `
 *  10. Each survey that is a SMART goal has the keys in its survey settings JSON
 *      {
 *         "smartGoalTags": [
 *              "smart_goal_p_1",
 *              "smart_goal_p_2",
 *              "smart_goal_p_3",
 *              "smart_goal_p_4",
 *              "smart_goal_p_5"
 *         ],
 *         "moduleId": 8,
 *         "otherModuleIds": [3]
 *      }
 *  11. A report must be created with the handle "poiseReport",
 *      which uses the Poise report template and a report with the
 *      handle "smartReport", which uses the Smart report template
 *  12. To change the module header text, make sure each module in
 *      assignment settings JSON has the key "header"
 *  13. To change LessonCard title from "survey##", change the
 *      Navigation Title in the Player Settings GUI
 *  14. To change the POISE quick review/button/confirm modal language, add the following
 *      keys in the module settings in module1 (at the quest-level navigation JSON)
 *      {
 *          "poiseRetakeConfirmMessage": "..."
 *          "poiseRetakeConfirmButton": "..."
 *          "poiseRetakeAbortButton": "..."
 *          "poiseQuickReview": "<p>wysiwyg stuff</p>"
 *          "poiseQuickReviewTitle": "..."
 *          "retakeButtonTooltipText": "..."
 *      }
 */

const PoiseCourseWrapperMediator = ({
    actingPawnHash,
    actingPawnId,
    basePath,
    flowList: originalFlowList,
    language,
    mediatorProps,
    onAssignmentComplete,
    onModuleComplete,
    onFlowComplete,
    onFlowStarted,
    isPreviewMode,
    isNetworkError,
}) => {
    /* ----- CUSTOM HOOKS --------------------------------------------------- */
    const [currentPoiseDeployment, setCurrentPoiseDeployment] = useState(null); // {deploymentId, isStarted}
    const [mostRecentPoiseData, setMostRecentPoiseData] = useState(null);
    const [mostRecentSmartGoals, setMostRecentSmartGoals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    /* ---------------------------------------------------------------------- */

    const {
        availableTabs,
        currentTabs,
        flowList,
        isCourseComplete,
        isTabMediatorComplete,
        mediatorTabData,
        onClickTab,
        onFlowCompleteLocal,
        onFlowStartedLocal,
        setFlowList,
    } = useCourseWrapperMediator({
        basePath,
        mediatorProps,
        onAssignmentComplete,
        onFlowComplete,
        onFlowStarted,
        originalFlowList,
        mostRecentPoiseData, // needed to decide whether registration is complete
    });
    const params = useParams();
    const currTab = params.courseWrapperTab;
    const { certificate, courseDescription, resources, setIsShowingLayoutBgColor } = mediatorProps;

    const renderTabs = () => {
        if (!availableTabs.filter(tabName => !['course', 'registration'].includes(tabName)).length) {
            return null;
        }

        return (
            <div className={style.section}>
                <ul className="nav nav-tabs">
                    {currentTabs.map(tabKey => {
                        return (
                            <Tab
                                currTab={currTab}
                                key={tabKey}
                                mediatorProps={mediatorProps}
                                onClickTab={() => {
                                    if (!isPreviewMode) {
                                        onPoiseComplete(); // update poise data in the background
                                    }
                                    onClickTab(tabKey);
                                }}
                                tabKey={tabKey}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    };

    const isSubMediator = ['registration', 'course', 'courseEval', 'finalTest'].includes(currTab);
    // whenever component mounts or changes, see if we should toggle background
    useEffect(() => {
        if (!isSubMediator && setIsShowingLayoutBgColor) {
            setIsShowingLayoutBgColor(false);
        } else {
            setIsShowingLayoutBgColor(true);
        }
        setFlowList(
            flowList.map(f => {
                delete f.completedLocally;
                return f;
            })
        );
    }, [currTab]);

    /* ----- CUSTOM USE EFFECT ---------------------------------------------- */
    async function setPoiseData() {
        const { id: pawnId, respondent_hash: hash } = Fortress.user.acting;
        const response = await client.query({
            query: poiseDeploymentIdQuery,
            variables: {
                pawnId,
                language,
            },
            fetchPolicy: 'network-only',
        });

        const smartGoalData = _get(response, 'data.flows.main.devTags', {});
        if (smartGoalData) {
            delete smartGoalData.__typename;
        }
        setMostRecentSmartGoals(smartGoalData);

        const poiseData = _get(response, 'data.flows.assignment.mostRecentCompleteData[0]', null);
        const mostRecentStartedId = _get(
            response,
            'data.flows.assignment.mostRecentStartedData[0].deployment.deploymentId',
            null
        );
        const parentDeploymentId = _get(response, 'data.flows.assignment.parent.deploymentId', null);
        if (!parentDeploymentId) {
            console.error(
                'Could not find deploymentId of the parent deployment. ' +
                    "Is there an assignment with handle 'poise' and deployment " +
                    "with the handle 'poiseParent'?"
            );
            return;
        }

        const poiseFlowProps = _get(response, 'data.flows.assignment.flow', {});

        if (poiseData && poiseData.devTags) {
            delete poiseData.__typename;
            delete poiseData.devTags.__typename;
        }
        setMostRecentPoiseData(poiseData);

        let deploymentList = _get(response, 'data.flows.assignment.deploymentList', []) || [];
        let deploymentId;
        // if first time taking the poise part
        if (!poiseData) {
            const firstDeployment = deploymentList.find(deployment => parseInt(deployment.timepoint, 10) === 1);
            deploymentId = firstDeployment && firstDeployment.deploymentId;
        }
        // If you've taken it before, lookup the deploymentId of the next timepoint
        else {
            const mostRecentDeployment = poiseData.deployment;
            const nextDeployment =
                mostRecentDeployment &&
                mostRecentDeployment.timepoint &&
                deploymentList.find(
                    d => parseInt(d.timepoint, 10) === parseInt(mostRecentDeployment.timepoint, 10) + 1
                );
            deploymentId = nextDeployment && nextDeployment.deploymentId;
        }

        // If you're the trailblazer, deploymentId will be falsey at this point
        if (!deploymentId) {
            const currentDeploymentResponse = await Axios.get(
                Boilerplate.route('api.assignmentDeployment.repeated', {
                    deploymentId: parentDeploymentId,
                    pawnId,
                    hash,
                })
            );
            deploymentId = _get(currentDeploymentResponse, 'data.data.deploymentId', null);
        }

        // If you can't deduce the deploymentId by this point, something is wrong
        if (!deploymentId) {
            console.error('Could not find or create a repeated deployment.');
        }

        let isStarted = mostRecentStartedId === deploymentId;
        setCurrentPoiseDeployment({ deploymentId, isStarted, ...poiseFlowProps });
        setIsLoading(false);
    }

    async function setSmartData() {
        const { id: pawnId } = Fortress.user.acting;
        const response = await client.query({
            query: smartGoalsQuery,
            variables: {
                pawnId,
            },
            fetchPolicy: 'network-only',
        });

        const smartGoalData = _get(response, 'data.flows.main.devTags', {});
        if (smartGoalData) {
            delete smartGoalData.__typename;
        }
        setMostRecentSmartGoals(smartGoalData);
    }

    useEffect(() => {
        setPoiseData();
    }, []);
    const onSmartComplete = () => {
        setSmartData();
    };
    const onPoiseComplete = () => {
        if (isPreviewMode) {
            setMostRecentPoiseData({ ...dummyPoiseData });
        } else {
            setPoiseData();
        }
    };
    /* ---------------------------------------------------------------------- */

    return (
        <div>
            {renderTabs()}
            {currTab === 'finalTest' && (
                <FinalTest
                    key={currTab}
                    flowList={flowList.filter(f => mediatorProps[currTab].options.includes(f.handle))}
                    completedMessage={mediatorTabData[currTab].completedMessage}
                    lockedMessage={mediatorTabData[currTab].lockedMessage}
                    onCompleteLocation={mediatorTabData[currTab].onCompleteLocation}
                    onFlowComplete={onFlowCompleteLocal}
                    onFlowStarted={onFlowStartedLocal}
                    onModuleComplete={onModuleComplete}
                    settings={mediatorProps[currTab]}
                    isLoading={isLoading}
                    isPreviewMode={isPreviewMode}
                    isNetworkError={isNetworkError}
                />
            )}
            {currTab === 'courseDescription' && <CourseDescription settings={courseDescription} />}
            {isSubMediator && currTab !== 'finalTest' && (
                <InvokeSubMediator
                    key={currTab}
                    currTab={currTab}
                    currentTabs={currentTabs}
                    flowList={flowList.filter(f => mediatorProps[currTab].options.includes(f.handle))}
                    completedMessage={mediatorTabData[currTab].completedMessage}
                    lockedMessage={mediatorTabData[currTab].lockedMessage}
                    onCompleteLocation={mediatorTabData[currTab].onCompleteLocation}
                    onFlowComplete={onFlowCompleteLocal}
                    onFlowStarted={onFlowStartedLocal}
                    onModuleComplete={onModuleComplete}
                    settings={mediatorProps[currTab]}
                    isLoading={isLoading}
                    currentPoiseDeployment={currentPoiseDeployment}
                    mostRecentPoiseData={mostRecentPoiseData}
                    mostRecentSmartGoals={mostRecentSmartGoals}
                    onPoiseComplete={onPoiseComplete}
                    onSmartComplete={onSmartComplete}
                    isPreviewMode={isPreviewMode}
                    isNetworkError={isNetworkError}
                />
            )}
            {currTab === 'certificate' && (
                <Certificate
                    actingPawnHash={actingPawnHash}
                    actingPawnId={actingPawnId}
                    hide={
                        !isCourseComplete || !isTabMediatorComplete('finalTest') || !isTabMediatorComplete('courseEval')
                    }
                    language={language}
                    deploymentId={flowList.length ? flowList[0].responseSet : ''}
                    settings={certificate}
                />
            )}
            {currTab === 'resources' && <Resources settings={resources} isCourseComplete={isCourseComplete} />}
        </div>
    );
};

PoiseCourseWrapperMediator.propTypes = {
    actingPawnHash: PropTypes.string,
    actingPawnId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    basePath: PropTypes.string,
    flowList: PropTypes.array.isRequired,
    language: PropTypes.string,
    mediatorProps: PropTypes.object,
    onAssignmentComplete: PropTypes.func,
    onFlowComplete: PropTypes.func,
    onFlowStarted: PropTypes.func,
    onModuleComplete: PropTypes.func,
    isPreviewMode: PropTypes.bool,
    isNetworkError: PropTypes.bool,
};

export default PoiseCourseWrapperMediator;
