import React from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';

const Resources = ({ isCourseComplete, settings }) => {
    if (!isCourseComplete && settings.lockedUntilCompletion) {
        return <div>{settings.tabName} may only be accessed after completing the course.</div>;
    }
    return (
        <div>
            <ResourceCenter handle={settings.resourceCenter} hideShareButton />
        </div>
    );
};

export default Resources;
