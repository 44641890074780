import React from 'react';
import { useHistory } from 'react-router-dom';
import Mediator from 'cccisd-laravel-assignment/dist/components/Mediator';
import navigations from 'cccisd-laravel-assignment/dist/components/navigations';

const FinalTest = ({
    completedMessage,
    flowList,
    lockedMessage,
    onCompleteLocation,
    onFlowComplete,
    onFlowStarted,
    onModuleComplete,
    settings,
    isLoading,
    isPreviewMode,
    isNetworkError,
}) => {
    if (isLoading) {
        return null;
    }

    if (completedMessage) {
        return <div>{completedMessage}</div>;
    }

    if (lockedMessage) {
        return <div>{lockedMessage}</div>;
    }

    // this app was built custom before useRepeatingFinalTest was a thing;
    // it would be extra dev work to allow it
    if (settings && settings.useRepeatingFinalTest) {
        return (
            <div>
                <p className="alert alert-danger">Repeating Final Test feature is not available in this build.</p>
                <p>
                    Please contact 3C Institute for support at <a href="mailto:support@3cisd.com">support@3cisd.com</a>{' '}
                    or by phone at (919) 677-0102.
                </p>
            </div>
        );
    }

    const history = useHistory();

    let SelectedMediator = Mediator;
    const mediatorProps = !Array.isArray(settings.navigationSettings) ? settings.navigationSettings : {};

    const language = flowList.length ? flowList[0].language : '';

    const currentNavigation = navigations.find(item => item.handle === settings.navigation);

    if (currentNavigation && currentNavigation.handle !== 'noSidebar') {
        SelectedMediator = currentNavigation.mediatorComponent;
    }

    const onComplete = () => {
        if (onCompleteLocation) {
            history.push(onCompleteLocation);
        }
    };

    return (
        <SelectedMediator
            flowList={flowList}
            language={language}
            mediatorProps={mediatorProps}
            onComplete={onComplete}
            onFlowComplete={onFlowComplete}
            onFlowStarted={onFlowStarted}
            onModuleComplete={onModuleComplete}
            isPreviewMode={isPreviewMode}
            isNetworkError={isNetworkError}
        />
    );
};

export default FinalTest;
