import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'SMART Goals Report',
    filterFields: [],
    otherFilterOptions: [],
    hideFilterBar: true,
};

const Component = () => {
    return (
        <BuilderBlock title="Header">
            <Field name="title" component={CccisdInput} label="Title" />
        </BuilderBlock>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({})(Component);
