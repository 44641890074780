import React from 'react';
import { useHistory } from 'react-router-dom';
import Mediator from 'cccisd-laravel-assignment/dist/components/Mediator';
import navigations from 'cccisd-laravel-assignment/dist/components/navigations';

const InvokeSubMediator = ({
    completedMessage,
    currTab,
    currentTabs,
    flowList,
    lockedMessage,
    onCompleteLocation,
    onFlowComplete,
    onFlowStarted,
    onModuleComplete,
    settings,
    isLoading,
    currentPoiseDeployment,
    mostRecentPoiseData,
    mostRecentSmartGoals,
    onPoiseComplete,
    onSmartComplete,
    isPreviewMode,
    isNetworkError,
}) => {
    const history = useHistory();

    if (completedMessage && !isLoading) {
        if (currTab === 'registration' && !currentTabs.includes('registration') && onCompleteLocation) {
            history.push(onCompleteLocation);
        }
        return <div>{completedMessage}</div>;
    }
    if (lockedMessage && !isLoading) {
        return <div>{lockedMessage}</div>;
    }

    let SelectedMediator = Mediator;
    const mediatorProps = !Array.isArray(settings.navigationSettings) ? settings.navigationSettings : {};

    const language = flowList.length ? flowList[0].language : '';

    const currentNavigation = navigations.find(item => item.handle === settings.navigation);

    if (currentNavigation && currentNavigation.handle !== 'noSidebar') {
        SelectedMediator = currentNavigation.mediatorComponent;
    }

    const onComplete = () => {
        if (onCompleteLocation) {
            history.push(onCompleteLocation);
        }
    };

    return (
        <SelectedMediator
            flowList={flowList}
            language={language}
            mediatorProps={mediatorProps}
            onComplete={onComplete}
            onFlowComplete={onFlowComplete}
            onFlowStarted={onFlowStarted}
            onModuleComplete={onModuleComplete}
            isLoading={isLoading}
            currentPoiseDeployment={currentPoiseDeployment}
            mostRecentPoiseData={mostRecentPoiseData}
            mostRecentSmartGoals={mostRecentSmartGoals}
            onPoiseComplete={onPoiseComplete}
            onSmartComplete={onSmartComplete}
            isPreviewMode={isPreviewMode}
            isNetworkError={isNetworkError}
        />
    );
};

export default InvokeSubMediator;
