import React from 'react';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';

import Placeholder from 'cccisd-icons/user';
import AudienceIcon from 'cccisd-icons/users4';
import CreditHoursIcon from 'cccisd-icons/database';
import DurationIcon from 'cccisd-icons/stopwatch';

const CourseDescription = ({ settings }) => {
    const {
        pageWidth,
        introduction,
        learningObjectives,
        presenters,
        audience,
        creditHours,
        duration,
        finalTestInstructions,
        ceApprovalStatements,
        specialAssistance,
    } = settings;

    const getPageWidth = () => {
        if (pageWidth === 'small') return '700px';
        if (pageWidth === 'large') return '1000px';
        return '850px';
    };

    const renderIconAndDescriptionField = (field, defaultTitle, getDefaultIcon) => {
        return (
            field &&
            field.description && (
                <div className={style.section}>
                    <h4 className={style.sectionHeader}>{field.title || defaultTitle}</h4>
                    <div className={style.iconAndDescriptionContainer}>
                        {field.icon === 'default' && (
                            <span
                                style={{
                                    color: field.iconColor,
                                    fontSize: '30px',
                                    marginRight: '1em',
                                }}
                            >
                                {getDefaultIcon()}
                            </span>
                        )}

                        {field.icon === 'upload' && field.imageDetails && (
                            <div
                                className={style.backgroundImage}
                                style={{
                                    backgroundImage: `url(${field.imageDetails.url})`,
                                }}
                            />
                        )}
                        {field.description && (
                            <span>
                                <Html content={field.description} />
                            </span>
                        )}
                    </div>
                </div>
            )
        );
    };

    const isShowPresenterImages = presenters.sections.find(presenter => presenter.image);

    return (
        <div style={{ maxWidth: getPageWidth() }}>
            {(introduction.main ||
                introduction.courseSections.find(
                    section => section.description || section.heading
                )) && (
                <div className={style.section}>
                    <h4 className={style.sectionHeader}>{introduction.title || 'Introduction'}</h4>
                    {introduction.main && (
                        <div className={style.mb3}>
                            <Html content={introduction.main} />
                        </div>
                    )}
                    {introduction.courseSections.length > 0 && (
                        <ul className="list-unstyled">
                            {introduction.courseSections
                                .filter(section => section.heading || section.description)
                                .map((section, i) => (
                                    <li
                                        key={section.key}
                                        className={`${style.introductionSections} ${i % 2 === 0 &&
                                            style.grayBg}`}
                                    >
                                        {section.heading && (
                                            <h5>
                                                <Html content={`<b>${section.heading}</b>`} />
                                            </h5>
                                        )}
                                        {section.description && (
                                            <Html content={section.description} />
                                        )}
                                    </li>
                                ))}
                        </ul>
                    )}
                </div>
            )}
            {learningObjectives.sections.find(
                objective =>
                    objective.heading ||
                    objective.statement ||
                    objective.objectives.find(item => item.text)
            ) && (
                <div className={`${style.section} ${style.learningObjectives}`}>
                    <h4 className={style.sectionHeader}>
                        {learningObjectives.title || 'Learning Objectives'}
                    </h4>
                    <ul className="list-unstyled">
                        {learningObjectives.sections.map(objectiveSection => (
                            <li key={objectiveSection.key} className={style.mb3}>
                                {objectiveSection.heading && (
                                    <h5 className={style.mb2}>
                                        <Html content={`<b>${objectiveSection.heading}</b>`} />
                                    </h5>
                                )}
                                {objectiveSection.statement && (
                                    <div className={style.mb2}>
                                        <Html content={objectiveSection.statement} />
                                    </div>
                                )}
                                {objectiveSection.objectives.find(objective => objective.text) && (
                                    <ul className={'list-unstyled ' + style.bulletedList}>
                                        {objectiveSection.objectives.map(
                                            objective =>
                                                objective.text && (
                                                    <li key={objective.key}>
                                                        <span
                                                            className={`${style.mb2} ${style.bullet}`}
                                                        >
                                                            <Html content={objective.text} />
                                                        </span>
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {presenters.sections.find(
                presenter =>
                    presenter.name ||
                    presenter.title ||
                    presenter.position ||
                    presenter.bio ||
                    presenter.image
            ) && (
                <div className={style.section}>
                    <h4 className={style.sectionHeader}>{presenters.title || 'Presenters'}</h4>
                    <ul className="list-unstyled">
                        {presenters.sections.map(presenter => (
                            <li key={presenter.key} className={style.mb3}>
                                {presenter.image && (
                                    <div
                                        style={{
                                            backgroundImage: `url(${presenter.image.url})`,
                                        }}
                                        className={`${style.backgroundImage} ${style.presenterImage} ${style.mb2}`}
                                    />
                                )}
                                {!presenter.image && isShowPresenterImages && (
                                    <div
                                        className={`${style.backgroundImage} ${style.mb2} ${style.presenterImage} ${style.presenterPlaceholder}`}
                                    >
                                        <Placeholder />
                                    </div>
                                )}
                                <div className={isShowPresenterImages && style.presenterInfo}>
                                    {presenter.name && <Html content={presenter.name} />}
                                    {presenter.title && <Html content={presenter.title} />}
                                    {presenter.position && <Html content={presenter.position} />}
                                    {presenter.bio && <Html content={presenter.bio} />}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {renderIconAndDescriptionField(audience, 'Audience', () => (
                <AudienceIcon />
            ))}
            {(finalTestInstructions.statement ||
                finalTestInstructions.bullets.find(bullet => bullet.text)) && (
                <div className={style.section}>
                    <h4 className={style.sectionHeader}>
                        {finalTestInstructions.title || 'Final Test Instructions'}
                    </h4>
                    {finalTestInstructions.statement && (
                        <Html content={finalTestInstructions.statement} />
                    )}
                    {finalTestInstructions.bullets.find(bullet => bullet.text) && (
                        <ul className={`list-unstyled ${style.bulletedList}`}>
                            {finalTestInstructions.bullets.map(
                                bullet =>
                                    bullet.text && (
                                        <li key={bullet.key}>
                                            <span className={`${style.mb2} ${style.bullet}`}>
                                                <Html content={bullet.text} />
                                            </span>
                                        </li>
                                    )
                            )}
                        </ul>
                    )}
                </div>
            )}
            {renderIconAndDescriptionField(creditHours, 'Credit Hours', () => (
                <CreditHoursIcon />
            ))}
            {renderIconAndDescriptionField(duration, 'Duration', () => (
                <DurationIcon />
            ))}
            {ceApprovalStatements.sections.find(
                statement => statement.title || statement.subtitle || statement.description
            ) && (
                <div className={style.section}>
                    <h4 className={style.sectionHeader}>
                        {ceApprovalStatements.title || 'CE Approval Statements'}
                    </h4>
                    {ceApprovalStatements.sections.map(statement => (
                        <div className={style.mb3} key={statement.key}>
                            {statement.title && (
                                <h5 className={style.mb1}>
                                    <Html content={`<b>${statement.title}</b>`} />
                                </h5>
                            )}
                            {statement.subtitle && (
                                <div className={style.mb1}>
                                    <Html content={`<i>${statement.subtitle}</i>`} />
                                </div>
                            )}
                            {statement.description && <Html content={statement.description} />}
                        </div>
                    ))}
                </div>
            )}
            {specialAssistance.content && (
                <div className={style.section}>
                    <h4 className={style.sectionHeader}>
                        {specialAssistance.title || 'Special Assistance'}
                    </h4>
                    <Html content={specialAssistance.content} />
                </div>
            )}
        </div>
    );
};

export default CourseDescription;
