import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { BuilderBlock, widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    poiseGraphHeader: 'Your Overall POISE Results',
    tableHeader: 'Your Individual Answers',
    physical: {
        title: 'Physical Well-Being',
        color: '#D12322',
        rows: {
            1: 'I get uninterrupted restful sleep for six or more hours most nights.',
            2: 'I eat nutritious foods, and I enjoy how they make me feel.',
            3: 'Most days, I exercise or physically move for 30+ minutes (7000+ steps).',
            4: 'I move around so I’m not sedentary or sitting for more than one hour at a time during the work day.',
            5: 'Music and/or artistic activities are a regular, enjoyable part of my life. ',
        },
    },
    occupational: {
        title: 'Occupational Well-Being',
        color: '#F4722B',
        rows: {
            1: 'My work in this school fits with and fulfills my sense of purpose.',
            2: 'I have a strong and reliable network of supportive people at work.',
            3: 'I manage my time well and complete most tasks on time.',
            4: 'I am able to achieve my goals despite obstacles that occur.',
            5: 'My supervisors and I communicate well and agree on priorities.',
        },
    },
    intellectual: {
        title: 'Intellectual Well-Being',
        color: '#FFB047',
        rows: {
            1: 'I stimulate my mind by reading, listening, or watching (e.g., tapes, videos, movies, etc.).',
            2: 'I regularly explore or pursue my interests or improve my skills.',
            3: 'I am able to help and enjoy others more than feel depleted by obstacles that interfere with helping others.',
            4: 'I journal, write (e.g., poetry or music), or capture pictures and take videos of my life story.',
            5: 'I rely on deeply held principles or spiritual values to guide my life.',
        },
    },
    social: {
        title: 'Social Well-Being',
        color: '#59A643',
        rows: {
            1: 'My relationships with other adults are satisfying.',
            2: 'I seek and benefit from the input and feedback of others.',
            3: 'I am aware of what annoys/triggers me, and I am aware of what I do that annoys/upsets others.',
            4: 'I support others and acknowledge (with words/actions) their successes.',
            5: 'I am able to resolve conflicts in most areas of my life.',
        },
    },
    emotional: {
        title: 'Emotional Well-Being',
        color: '#5D69D8',
        rows: {
            1: 'I can stay with and tolerate my painful emotions and learn from them.',
            2: 'I feel calm or serene most of the time.',
            3: 'I have developed reliable, effective ways to cope with stress.',
            4: 'I can manage my emotions to stay on task or work when necessary.',
            5: 'I enjoy living in the present and worry little about the past or future.',
        },
    },
};

const Component = props => {
    return (
        <>
            <BuilderBlock title="Headers">
                <Field
                    name="poiseGraphHeader"
                    component={CccisdInput}
                    label="POISE Overall Header"
                />
                <Field
                    name="tableHeader"
                    component={CccisdInput}
                    label="Individual Results Header"
                />
            </BuilderBlock>
            <BuilderBlock title="Physical Well-Being">
                <Field name="physical.rows.1" component={CccisdInput} label="Row 1 - Text" />
                <Field name="physical.rows.2" component={CccisdInput} label="Row 2 - Text" />
                <Field name="physical.rows.3" component={CccisdInput} label="Row 3 - Text" />
                <Field name="physical.rows.4" component={CccisdInput} label="Row 4 - Text" />
                <Field name="physical.rows.5" component={CccisdInput} label="Row 5 - Text" />
            </BuilderBlock>
            <BuilderBlock title="Occupational Well-Being">
                <Field name="occupational.rows.1" component={CccisdInput} label="Row 1 - Text" />
                <Field name="occupational.rows.2" component={CccisdInput} label="Row 2 - Text" />
                <Field name="occupational.rows.3" component={CccisdInput} label="Row 3 - Text" />
                <Field name="occupational.rows.4" component={CccisdInput} label="Row 4 - Text" />
                <Field name="occupational.rows.5" component={CccisdInput} label="Row 5 - Text" />
            </BuilderBlock>
            <BuilderBlock title="Intellectual Well-Being">
                <Field name="intellectual.rows.1" component={CccisdInput} label="Row 1 - Text" />
                <Field name="intellectual.rows.2" component={CccisdInput} label="Row 2 - Text" />
                <Field name="intellectual.rows.3" component={CccisdInput} label="Row 3 - Text" />
                <Field name="intellectual.rows.4" component={CccisdInput} label="Row 4 - Text" />
                <Field name="intellectual.rows.5" component={CccisdInput} label="Row 5 - Text" />
            </BuilderBlock>
            <BuilderBlock title="Social Well-Being">
                <Field name="social.rows.1" component={CccisdInput} label="Row 1 - Text" />
                <Field name="social.rows.2" component={CccisdInput} label="Row 2 - Text" />
                <Field name="social.rows.3" component={CccisdInput} label="Row 3 - Text" />
                <Field name="social.rows.4" component={CccisdInput} label="Row 4 - Text" />
                <Field name="social.rows.5" component={CccisdInput} label="Row 5 - Text" />
            </BuilderBlock>
            <BuilderBlock title="Emotional Well-Being">
                <Field name="emotional.rows.1" component={CccisdInput} label="Row 1 - Text" />
                <Field name="emotional.rows.2" component={CccisdInput} label="Row 2 - Text" />
                <Field name="emotional.rows.3" component={CccisdInput} label="Row 3 - Text" />
                <Field name="emotional.rows.4" component={CccisdInput} label="Row 4 - Text" />
                <Field name="emotional.rows.5" component={CccisdInput} label="Row 5 - Text" />
            </BuilderBlock>
        </>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
