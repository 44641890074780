import React from 'react';
import propTypes from 'prop-types';
import notification from 'cccisd-notification';
import PoisePlayer from '../../../../vendor/reports/reportTemplates/POISEReportTemplate/Player';
import PoiseWidgets from '../../../../vendor/reports/reportTemplates/POISEReportTemplate/widgets.js';
import SmartPlayer from '../../../../vendor/reports/reportTemplates/SMARTReportTemplate/Player';

const Appdefs = window.cccisd && window.cccisd.appDefs;

const reportHandleMapper = {
    poiseReport: {
        player: PoisePlayer,
        widgets: PoiseWidgets,
    },
    smartReport: {
        player: SmartPlayer,
        widgets: [],
    },
};

const ReportRender = props => {
    const { handle } = props;
    const settings =
        Appdefs &&
        Appdefs.reports &&
        Appdefs.reports.list &&
        Appdefs.reports.list.find(report => report.id === handle);

    if (!settings || !reportHandleMapper[handle]) {
        notification({
            message: `Cannot find a report where field "id" is equal to "${handle}"`,
            type: 'danger',
        });
        return null;
    }

    const Player = reportHandleMapper[handle].player;
    const widgets = reportHandleMapper[handle].widgets;
    return (
        <Player
            settings={settings.reportTemplateSettings}
            filters={[]}
            isPreview={false}
            widgets={widgets}
            {...props}
        />
    );
};

ReportRender.propTypes = {
    handle: propTypes.string.isRequired,
};

export default ReportRender;
