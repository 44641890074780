import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Modal from 'cccisd-modal';
import { Player } from 'cccisd-laravel-flow';
import IconLock from 'cccisd-icons/svg/lock4';
import IconPlay from 'cccisd-icons/svg/play4';
import IconReplay from 'cccisd-icons/svg/spinner11';
import ReportIcon from 'cccisd-icons/file-text2';
import { Html } from 'cccisd-wysiwyg';
import ReportRender from './ReportRender.js';
import ConfirmRetake from './ConfirmRetake';
import style from '../style.css';

const translate = {
    en: {
        lessonText: 'LESSON',
        play: 'Play',
        quickReview: 'QUICK REVIEW',
        replay: 'Replay',
        resume: 'Resume',
        retake: 'Retake',
    },
    es: {
        lessonText: 'LECCIÓN',
        play: 'Empezar',
        quickReview: 'REVISIÓN RÁPIDA',
        replay: 'Repetirse',
        resume: 'Reanudar',
        retake: 'Repetirse',
    },
};

const LessonCard = ({
    item,
    language,
    lessonNum,
    lessons = [],
    mostRecentSmartGoals,
    mostRecentPoiseData,
}) => {
    language = Object.keys(translate).includes(language) ? language : 'en';
    const action = getAction(item);
    const hasNextLesson = lessons.length >= lessonNum + 2;
    const lessonAreaRef = useRef();
    const [lessonAreaHeight, setLessonAreaHeight] = useState(109);

    useEffect(() => {
        if (lessonAreaRef.current && lessonAreaRef.current.offsetHeight !== lessonAreaHeight) {
            setLessonAreaHeight(lessonAreaRef.current.offsetHeight);
        }

        function handleResize() {
            if (lessonAreaRef.current && lessonAreaRef.current.offsetHeight !== lessonAreaHeight) {
                setLessonAreaHeight(lessonAreaRef.current.offsetHeight);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    function getAction(lesson) {
        // POISE lesson card only shows "Play" or "Resume"
        switch (true) {
            case lesson.isPoise && lesson.isStarted:
                return 'resume';
            case lesson.isPoise && !lesson.isStarted:
                return 'retake';
            case lesson.isCompleted:
                return 'replay';
            case lesson.isResume:
                return 'resume';
            default:
                return 'play';
        }
    }

    function renderActionButton() {
        let { isPoise } = item;
        let playIcon = item.isAllowed ? <IconPlay /> : <IconLock />;
        const buttonData = {
            play: { className: 'btn-info', icon: playIcon },
            retake: { className: 'btn-info', icon: null },
            resume: { className: 'btn-primary', icon: null },
            replay: { className: style.grayButton, icon: <IconReplay /> },
        };
        const buttonText = translate[language][action];
        const buttonInfo = buttonData[action];
        const button = (
            <button
                type="button"
                className={classnames(
                    'btn btn-block',
                    buttonInfo.className,
                    !item.isAllowed && 'disabled'
                )}
                onClick={isPoise ? null : item.setCurrent}
            >
                {buttonInfo.icon && (
                    <span className={style.actionIcon}>{buttonInfo.icon}&nbsp;</span>
                )}
                {buttonText}
            </button>
        );

        return isPoise ? (
            <ConfirmRetake
                button={button}
                item={item}
                deploymentId={mostRecentPoiseData.deployment.deploymentId}
            />
        ) : (
            button
        );
    }

    function renderQuickReview() {
        const { isPoise, smartGoalTags, quickReview, isCompleted } = item;
        const printableModal = (
            <Modal
                trigger={
                    <button type="button" className="btn btn-default">
                        Print
                    </button>
                }
                size="large"
                title={item.title}
            >
                <Player {...item} isPrintMode />
            </Modal>
        );

        if (!isPoise && !quickReview && (!smartGoalTags || !smartGoalTags.length)) {
            return (
                <div className={style.quickReviewWrapper}>
                    <div />
                    {isCompleted && (
                        <div className={style.quickReviewPrintButton}>{printableModal}</div>
                    )}
                </div>
            );
        }

        if (smartGoalTags && smartGoalTags.length) {
            const goalItems = [];
            smartGoalTags.forEach(tag => {
                if (mostRecentSmartGoals[tag]) {
                    goalItems.push(<li key={tag}>{mostRecentSmartGoals[tag]}</li>);
                }
            });
            return (
                <div className={style.quickReviewWrapper}>
                    <div>
                        <p className={style.quickReviewTitle}>SMART GOALS</p>
                        {goalItems.length > 0 ? (
                            <ul className={style.smartGoalsList}>{goalItems}</ul>
                        ) : (
                            <p>No current goals</p>
                        )}
                    </div>
                    {isCompleted && (
                        <div className={style.quickReviewPrintButton}>{printableModal}</div>
                    )}
                </div>
            );
        }

        if (isPoise) {
            return (
                <div className={style.quickReviewWrapper}>
                    <div>
                        {item.poiseQuickReview && (
                            <>
                                {item.poiseQuickReviewTitle && (
                                    <p className={style.quickReviewTitle}>
                                        {item.poiseQuickReviewTitle}
                                    </p>
                                )}
                                <Html content={item.poiseQuickReview} />
                            </>
                        )}
                    </div>
                    {isCompleted && (
                        <div className={style.quickReviewPrintButton}>
                            <Modal
                                trigger={
                                    <button type="button" className="btn btn-default">
                                        <ReportIcon />
                                        &nbsp;&nbsp;Report
                                    </button>
                                }
                                size="large"
                                title={item.title + ' Report'}
                            >
                                <ReportRender
                                    handle="poiseReport"
                                    deploymentId={mostRecentPoiseData.deployment.deploymentId}
                                />
                            </Modal>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div className={style.quickReviewWrapper}>
                <div>
                    <p className={style.quickReviewTitle}>{translate[language].quickReview}</p>
                    {quickReview && <Html content={item.quickReview} />}
                </div>
                {isCompleted && (
                    <div className={style.quickReviewPrintButton}>{printableModal}</div>
                )}
            </div>
        );
    }

    function renderLessonMain() {
        return (
            <div className={style.lessonCard} ref={lessonAreaRef}>
                <div className={style.lessonInnerWrapper}>
                    <p className={style.lessonText}>
                        {translate[language].lessonText} {lessonNum + 1}
                    </p>
                    <p>{item.title}</p>
                    {renderActionButton()}
                </div>
                <div
                    className={classnames(
                        style.quickReviewDivider,
                        !!item.quickReview && style.quickReviewDividerColor
                    )}
                />
                {renderQuickReview()}
            </div>
        );
    }

    function renderLessonStatus() {
        return (
            <>
                <div className={classnames(style.lessonStatusOuter, style[`lesson${action}`])}>
                    {action === 'resume' && (
                        <div
                            className={classnames(style.lessonplay, style.lessonStatusOuterPartial)}
                        />
                    )}
                    <div className={style.lessonStatusInner}>{lessonNum + 1}</div>
                </div>
                {hasNextLesson && (
                    <div
                        className={classnames(style.lessonStatusBeam, style[`lesson${action}`])}
                        style={{ height: `${lessonAreaHeight}px` }}
                    />
                )}
            </>
        );
    }

    return (
        <div>
            {renderLessonStatus()}
            {renderLessonMain()}
        </div>
    );
};

export default LessonCard;
