import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import settingsWrapper from 'cccisd-laravel-assignment/dist/helpers/settingsWrapper';

export const initialValues = {
    mustTakeInOrder: true,
};

const Settings = ({ questSettings }) => (
    <>
        <Field
            name="mustTakeInOrder"
            component={CccisdToggle}
            label="User must take surveys in order"
        />
    </>
);

export default settingsWrapper({ initialValues })(Settings);
