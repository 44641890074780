import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import format from 'date-fns/format';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import { Report, Page } from 'cccisd-laravel-report';
import PoiseBarChart from '../../../../../../../components/CourseWithModules/Mediator/PoiseGraph';
import poiseDataQuery from './poiseDataQuery.graphql';
import poiseDeploymentIdQuery from './poiseDeploymentId.graphql';
import PhysicalTab from './images/Physical.png';
import OccupationalTab from './images/Occupational.png';
import IntellectualTab from './images/Intellectual.png';
import SocialTab from './images/Social.png';
import EmotionalTab from './images/Emotional.png';
import FooterLogo from './images/Footer_Logo.png';
import style from './style.css';

const key = [
    {
        variable: 'mx_24_answer_1_mc_1_answer',
        devTag: 'restful_sleep',
        section: 'physical',
    },
    {
        variable: 'mx_24_answer_2_mc_1_answer',
        devTag: 'nutritious_food',
        section: 'physical',
    },
    {
        variable: 'mx_24_answer_3_mc_1_answer',
        devTag: 'exercise',
        section: 'physical',
    },
    {
        variable: 'mx_24_answer_4_mc_1_answer',
        devTag: 'not_sedentary',
        section: 'physical',
    },
    {
        variable: 'mx_24_answer_5_mc_1_answer',
        devTag: 'artistic_activities',
        section: 'physical',
    },
    {
        variable: 'mx_26_answer_1_mc_1_answer',
        devTag: 'sense_of_purpose',
        section: 'occupational',
    },
    {
        variable: 'mx_26_answer_2_mc_1_answer',
        devTag: 'supportive_people',
        section: 'occupational',
    },
    {
        variable: 'mx_26_answer_3_mc_1_answer',
        devTag: 'time_management',
        section: 'occupational',
    },
    {
        variable: 'mx_26_answer_4_mc_1_answer',
        devTag: 'achieve_goals',
        section: 'occupational',
    },
    {
        variable: 'mx_26_answer_5_mc_1_answer',
        devTag: 'supervisor_communication',
        section: 'occupational',
    },
    {
        variable: 'mx_28_answer_2_mc_1_answer',
        devTag: 'stimulate_mind',
        section: 'intellectual',
    },
    {
        variable: 'mx_28_answer_3_mc_1_answer',
        devTag: 'pursue_interests',
        section: 'intellectual',
    },
    {
        variable: 'mx_28_answer_4_mc_1_answer',
        devTag: 'help_others',
        section: 'intellectual',
    },
    {
        variable: 'mx_28_answer_5_mc_1_answer',
        devTag: 'life_story',
        section: 'intellectual',
    },
    {
        variable: 'mx_28_answer_6_mc_1_answer',
        devTag: 'principles_values',
        section: 'intellectual',
    },
    {
        variable: 'mx_30_answer_1_mc_1_answer',
        devTag: 'satisfying_relationships',
        section: 'social',
    },
    {
        variable: 'mx_30_answer_2_mc_1_answer',
        devTag: 'beneficial_feedback',
        section: 'social',
    },
    {
        variable: 'mx_30_answer_3_mc_1_answer',
        devTag: 'annoyance_awareness',
        section: 'social',
    },
    {
        variable: 'mx_30_answer_4_mc_1_answer',
        devTag: 'acknowledge_success',
        section: 'social',
    },
    {
        variable: 'mx_30_answer_5_mc_1_answer',
        devTag: 'resolve_conflicts',
        section: 'social',
    },
    {
        variable: 'mx_32_answer_1_mc_1_answer',
        devTag: 'painful_emotions',
        section: 'emotional',
    },
    {
        variable: 'mx_32_answer_2_mc_1_answer',
        devTag: 'feel_calm',
        section: 'emotional',
    },
    {
        variable: 'mx_32_answer_3_mc_1_answer',
        devTag: 'effective_coping',
        section: 'emotional',
    },
    {
        variable: 'mx_32_answer_4_mc_1_answer',
        devTag: 'manage_emotions',
        section: 'emotional',
    },
    {
        variable: 'mx_32_answer_5_mc_1_answer',
        devTag: 'enjoy_present',
        section: 'emotional',
    },
];
const fakeData = {
    poise_p_average: 3,
    poise_o_average: 3,
    poise_i_average: 3,
    poise_s_average: 5,
    poise_e_average: 1,
    restful_sleep: 1,
    nutritious_food: 2,
    exercise: 3,
    not_sedentary: 4,
    artistic_activities: 5,
    sense_of_purpose: 1,
    supportive_people: 2,
    time_management: 3,
    achieve_goals: 4,
    supervisor_communication: 5,
    stimulate_mind: 1,
    pursue_interests: 2,
    help_others: 3,
    life_story: 4,
    principles_values: 5,
    satisfying_relationships: 5,
    beneficial_feedback: 5,
    annoyance_awareness: 5,
    acknowledge_success: 5,
    resolve_conflicts: 5,
    painful_emotions: 1,
    feel_calm: 1,
    effective_coping: 1,
    manage_emotions: 1,
    enjoy_present: 1,
};
const Fortress = window.cccisd.fortress;
const year = format(new Date(), 'yyyy');

const renderFooter = number => {
    return (
        <div style={{ marginTop: `${number === 1 ? '20px' : '210px'}` }}>
            <hr />
            <footer className={style.reportFooter}>
                <div className={style.footerText}>
                    &copy; {year} <span style={{ fontSize: '14px' }}>Medstar Georgetown WISE Center</span>
                </div>
                <div className={style.footerLogo}>
                    <img src={FooterLogo} alt="footerLogo" width="180px" height="36px" />
                </div>
            </footer>
            <div style={{ textAlign: 'center', fontSize: '10pt', color: '#b5b5b5' }}>Page {number} of 2</div>
        </div>
    );
};

const ReportTable = props => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    // used to delay rendering the POISE graph until PDF is done resizing
    // or else graph scale will be wrong
    const [isDoneResizing, setIsDoneResizing] = useState(false);

    const { renderHeader } = props.widgetProps;
    const { poiseGraphHeader, tableHeader } = props.settings;

    useEffect(() => {
        async function getPoiseData() {
            let realData = fakeData;
            if (!props.isPreview) {
                let deploymentId = props.widgetProps.deploymentId;
                // deploymentId will be null when using Report entity in Survey Builder
                if (!deploymentId) {
                    const resp = await client.query({
                        query: poiseDeploymentIdQuery,
                        variables: {
                            pawnId: Fortress.user.acting.id,
                        },
                        fetchPolicy: 'network-only',
                    });
                    deploymentId = _get(resp, 'data.flows.assignment.deploymentList[0].deploymentId', null);
                }

                const response = await client.query({
                    query: poiseDataQuery,
                    variables: {
                        pawnId: Fortress.user.acting.id,
                        deploymentId,
                    },
                    fetchPolicy: 'network-only',
                });
                realData = response.data.flows.assignmentProgress.devTags;
            }

            setData(realData);
            setLoading(false);
            setTimeout(() => setIsDoneResizing(true), 500);
        }
        getPoiseData();
    }, []);

    const getSectionData = section => {
        const sectionData = key.filter(item => item.section.includes(section));

        const values = sectionData.map((item, i) => {
            const text = props.settings[item.section].rows[i + 1];
            return { text, value: data[item.devTag] };
        });
        return values;
    };

    const getData = () => {
        const physicalData = getSectionData('physical');
        const occupationalData = getSectionData('occupational');
        const intellectualData = getSectionData('intellectual');
        const socialData = getSectionData('social');
        const emotionalData = getSectionData('emotional');

        return {
            firstTable: [
                { section: 'physical', rows: physicalData },
                { section: 'occupational', rows: occupationalData },
            ],
            secondTable: [
                { section: 'intellectual', rows: intellectualData },
                { section: 'social', rows: socialData },
                { section: 'emotional', rows: emotionalData },
            ],
        };
    };
    const calculateWidth = score => {
        return score * 20 * 0.27 + 'rem';
    };

    const renderSectionRows = section => {
        const { rows } = section;
        const sectionLabels = {
            physical: PhysicalTab,
            occupational: OccupationalTab,
            intellectual: IntellectualTab,
            social: SocialTab,
            emotional: EmotionalTab,
        };
        const barColors = {
            physical: '#D12322',
            occupational: '#F4722B',
            intellectual: '#FFB047',
            social: '#59A643',
            emotional: '#5D69D8',
        };
        return (
            <React.Fragment key={section.section}>
                {rows.map((row, i) => (
                    <tr className={i === 4 ? style.tableRowLast : style.tableRow} key={i}>
                        {i === 0 && (
                            <td className={style.imageCell} rowSpan={5}>
                                <img src={sectionLabels[section.section]} alt={section.section} />
                            </td>
                        )}
                        <td className={style.rowText}>{row.text}</td>
                        <td className={style.scoreCell}>
                            <div className={style.scoreWrapper}>
                                <div
                                    className={style.scoreBar}
                                    style={{
                                        width: `${calculateWidth(row.value)}`,
                                        backgroundColor: `${barColors[section.section]}`,
                                    }}
                                />
                                <div className={style.scoreBox} />
                                <div className={style.scoreBox} />
                                <div className={style.scoreBox} />
                                <div className={style.scoreBox} />
                                <div className={style.scoreBox} />
                            </div>
                        </td>
                    </tr>
                ))}
            </React.Fragment>
        );
    };
    const renderTableHead = () => {
        return (
            <thead>
                <tr>
                    <th colSpan={2} />
                    <th className={style.legend}>
                        <div className={style.legendBox}>Not at all</div>
                        <div className={style.legendBox}>A little</div>
                        <div className={style.legendBox}>Moderately</div>
                        <div className={style.legendBox}>Mostly</div>
                        <div className={style.legendBox}>Almost always</div>
                    </th>
                </tr>
            </thead>
        );
    };
    const renderTables = number => {
        const tableData = getData();
        let currentTable = number === 1 ? tableData.firstTable : tableData.secondTable;

        return (
            <Page>
                <div className={style.tableWrapper}>
                    {number === 1 && (
                        <>
                            {renderHeader()}
                            <h4 className={`${style.sectionTitle} ${style.top}`}>{poiseGraphHeader}</h4>
                            {isDoneResizing && <PoiseBarChart recommendedModules={[]} devTags={data} height={200} />}
                            <h4 className={style.sectionTitle}>{tableHeader}</h4>
                        </>
                    )}
                    <table className={style.reportTable}>
                        {renderTableHead()}
                        <tbody>
                            {currentTable.map(item => {
                                return <React.Fragment key={item.section}>{renderSectionRows(item)}</React.Fragment>;
                            })}
                        </tbody>
                    </table>
                    {renderFooter(number)}
                </div>
            </Page>
        );
    };

    const now = new Date();
    return (
        <Loader loading={loading} removeChildren>
            <Report downloadFilename={`POISE_${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}`}>
                {data && renderTables(1)}
                {data && renderTables(2)}
            </Report>
        </Loader>
    );
};

ReportTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgetProps: PropTypes.object,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
ReportTable.defaultProps = {
    filters: {},
};

export default ReportTable;
