import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import CourseWrapperMediator from './CourseWrapperMediator';

const MediatorRoot = ownProps => {
    return (
        <Route
            path={`${ownProps.match.path}/:courseWrapperTab?`}
            render={props => (
                <CourseWrapperMediator {...ownProps} {...props} basePath={ownProps.match.url} />
            )}
        />
    );
};

export default withRouter(MediatorRoot);
