import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

const tabs = [
    'courseDescription',
    'courseEval',
    'course',
    'finalTest',
    'certificate',
    'resources',
    'registration',
];

export const useCourseWrapperMediator = ({
    basePath,
    mediatorProps,
    onAssignmentComplete,
    onFlowComplete,
    onFlowStarted,
    originalFlowList,
    mostRecentPoiseData,
}) => {
    const [flowList, setFlowList] = useState([...originalFlowList]);
    const [hasVisitedCertificate, setHasVisitedCertificate] = useState(false);
    const history = useHistory();
    const params = useParams();
    const {
        certificate,
        course,
        courseDescription,
        courseEval,
        finalTest,
        registration,
        resources,
    } = mediatorProps;

    const startedComplete = originalFlowList.filter(f => !f.isComplete).length === 0;

    const isCourseComplete =
        flowList.filter(
            f => mediatorProps.course.options.includes(f.handle) && f.isRequired && !f.isComplete
        ).length === 0;

    const isCourseFullyComplete = isTabMediatorComplete('course');
    const isCourseEvalComplete = isTabMediatorComplete('courseEval');
    const isFinalTestComplete = isTabMediatorComplete('finalTest');
    const isRegistrationComplete = mostRecentPoiseData && isTabMediatorComplete('registration');
    const availableTabs = tabs.filter(tab => mediatorProps[tab] && mediatorProps[tab].toggle);
    const currentTabs = getCurrentTabs();

    useEffect(() => {
        if (!params.courseWrapperTab || !availableTabs.includes(params.courseWrapperTab)) {
            history.push(`${basePath}/${currentTabs[0]}`);
        }
    }, []);

    useEffect(() => {
        if (
            !hasVisitedCertificate &&
            params.courseWrapperTab === 'certificate' &&
            isCourseComplete &&
            isFinalTestComplete &&
            isCourseEvalComplete
        ) {
            setHasVisitedCertificate(true);
        }
    }, [params]);

    function getCurrentTabs() {
        const newTabs = [];
        // First Tab
        if (courseDescription && courseDescription.toggle) {
            newTabs.push('courseDescription');
        }

        // Second Tab
        if (registration && registration.toggle) {
            const registrationSurveys = flowList.filter(
                f => registration.options.includes(f.handle) && !f.isComplete
            );
            if (registrationSurveys.length || !mostRecentPoiseData) {
                newTabs.push('registration');
            }
        }
        if (!newTabs.includes('registration')) {
            newTabs.push('course');
        }

        // Third Tab
        const thirdTab = getThirdTab();
        if (thirdTab) {
            newTabs.push(thirdTab);
        }

        // Fourth Tab
        if (resources && resources.toggle) {
            if (!isCourseComplete && resources.lockedUntilCompletion) {
                newTabs.push('resources_locked');
            } else {
                newTabs.push('resources');
            }
        }

        return newTabs;
    }

    function getThirdTab() {
        let thirdTab;

        if (finalTest && finalTest.toggle) {
            thirdTab = 'finalTest';
            if (!isFinalTestComplete && finalTest.lockedUntilCompletion) {
                if (!isCourseComplete) {
                    thirdTab = 'finalTest_locked';
                }
            }
        }

        if (courseEval && courseEval.toggle && isFinalTestComplete) {
            thirdTab = 'courseEval';
            if (!isCourseEvalComplete && courseEval.lockedUntilCompletion) {
                if (!isCourseComplete) {
                    thirdTab = 'courseEval_locked';
                }
            }
        }

        if (certificate && certificate.toggle && isFinalTestComplete && isCourseEvalComplete) {
            if (!isCourseComplete) {
                thirdTab = 'certificate_locked';
            } else {
                thirdTab = 'certificate';
            }
        }

        return thirdTab;
    }

    function isTabMediatorComplete(tabKey) {
        let isComplete = true;
        if (mediatorProps[tabKey] && mediatorProps[tabKey].toggle) {
            isComplete =
                flowList.filter(
                    f => mediatorProps[tabKey].options.includes(f.handle) && !f.isComplete
                ).length === 0;
        }
        return isComplete;
    }

    function getMediatorTabData() {
        const onCompleteLocations = getOnCompleteLocations();

        const getMustCompleteString = (must, before) => {
            return `You must first complete the ${must} before starting the ${before}.`;
        };

        const getAlreadyCompleteString = tabName => {
            return `You have already completed the ${tabName}.`;
        };

        const courseEvalLockedMessage = () => {
            if (!isCourseComplete) {
                if (finalTest.toggle) {
                    return getMustCompleteString(
                        `${course.tabName} and ${finalTest.tabName}`,
                        courseEval.tabName
                    );
                }
                return getMustCompleteString(course.tabName, courseEval.tabName);
            }
            if (finalTest.toggle && !isFinalTestComplete) {
                return getMustCompleteString(finalTest.tabName, courseEval.tabName);
            }
            return null;
        };

        return {
            course: {
                completedMessage:
                    isCourseFullyComplete && !course.allowRetake
                        ? getAlreadyCompleteString(course.tabName)
                        : null,
                lockedMessage: !isRegistrationComplete
                    ? getMustCompleteString(registration.tabName, course.tabName)
                    : null,
                onCompleteLocation: onCompleteLocations.course,
            },
            courseEval: {
                completedMessage: isCourseEvalComplete
                    ? getAlreadyCompleteString(courseEval.tabName)
                    : null,
                lockedMessage: courseEvalLockedMessage(),
                onCompleteLocation: onCompleteLocations.courseEval,
            },
            finalTest: {
                completedMessage: isFinalTestComplete
                    ? getAlreadyCompleteString(finalTest.tabName)
                    : null,
                lockedMessage:
                    !isCourseComplete && finalTest.lockedUntilCompletion
                        ? getMustCompleteString(course.tabName, finalTest.tabName)
                        : null,
                onCompleteLocation: onCompleteLocations.finalTest,
            },
            registration: {
                completedMessage:
                    isRegistrationComplete && mostRecentPoiseData
                        ? getAlreadyCompleteString(registration.tabName)
                        : null,
                onCompleteLocation: onCompleteLocations.registration,
            },
        };
    }

    function getOnCompleteLocations() {
        let courseOnComplete = '';
        if (finalTest.toggle && !isFinalTestComplete) {
            courseOnComplete = 'finalTest';
        } else if (courseEval.toggle && !isCourseEvalComplete) {
            courseOnComplete = 'courseEval';
        } else if (certificate.toggle && !hasVisitedCertificate) {
            courseOnComplete = 'certificate';
        }

        let finalTestOnComplete = '';
        if (courseEval.toggle) {
            finalTestOnComplete = 'courseEval';
        } else if (certificate.toggle) {
            finalTestOnComplete = 'certificate';
        }

        let courseEvalOnComplete = certificate.toggle ? 'certificate' : '';

        const addFullPath = path => {
            if (!path) {
                return null;
            }
            return `${basePath}/${path}`;
        };

        return {
            registration: addFullPath('course'),
            course: addFullPath(courseOnComplete),
            finalTest: addFullPath(finalTestOnComplete),
            courseEval: addFullPath(courseEvalOnComplete),
        };
    }

    function onClickTab(tabKey) {
        history.push(`${basePath}/${tabKey}`);
    }

    async function onFlowCompleteLocal(flow, completionInfo = {}) {
        if (!flow.isComplete) {
            flow.completedLocally = true;
        }
        flow.isComplete = true;
        let updatedFlowList = [...flowList.filter(f => f.handle !== flow.handle), flow].sort(
            sortByOrdinalPosition
        );

        setFlowList(updatedFlowList);

        await onFlowComplete(flow, completionInfo);

        if (
            !updatedFlowList.filter(f => !f.isComplete && f.isRequired).length &&
            !startedComplete
        ) {
            onAssignmentComplete();
        }
    }

    function onFlowStartedLocal(flow) {
        flow.isStarted = true;
        onFlowStarted(flow);
        let updatedFlowList = [...flowList.filter(f => f.handle !== flow.handle), flow].sort(
            sortByOrdinalPosition
        );

        setFlowList(updatedFlowList);
    }

    function sortByOrdinalPosition(e1, e2) {
        if (e1.ordinalPosition < e2.ordinalPosition) {
            return -1;
        }
        if (e1.ordinalPosition > e2.ordinalPosition) {
            return 1;
        }
        return 0;
    }

    return {
        availableTabs,
        currentTabs,
        flowList,
        isCourseComplete,
        isTabMediatorComplete,
        mediatorTabData: getMediatorTabData(),
        onClickTab,
        onFlowCompleteLocal,
        onFlowStartedLocal,
        setFlowList,
    };
};

useCourseWrapperMediator.propTypes = {
    basePath: PropTypes.string,
    mediatorProps: PropTypes.object,
    onAssignmentComplete: PropTypes.func,
    onFlowComplete: PropTypes.func,
    onFlowStarted: PropTypes.func,
    originalFlowList: PropTypes.array.isRequired,
};

export default useCourseWrapperMediator;
