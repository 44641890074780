import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './ConfirmRetake.css';
import Modal from 'cccisd-modal';
import ReportRender from './ReportRender';
import Button from 'cccisd-click-button';

const ConfirmRetake = ({ button, item, deploymentId }) => {
    const [isReportVisible, setIsReportVisible] = useState(false);

    // we don't know exactly how long the report will take to download,
    // but don't let the user click twice in less than 4 seconds
    const [isPdfDownloading, setIsPdfDownloading] = useState(false);

    function handleClickDownload() {
        // Try to simulate a click event on the "Download as PDF" button.
        // If this hack doesn't work due to Report package changes, just set
        // state to show the report where the user can download it themselves.
        const downloadButton = document
            .getElementById('report-container-hidden')
            .querySelector('button.btn.btn-primary[type=submit]');

        if (downloadButton) {
            downloadButton.click();
            setIsPdfDownloading(true);
            setTimeout(() => {
                setIsPdfDownloading(false);
            }, 4000);
        } else {
            setIsReportVisible(true);
        }
    }

    function renderModalBody() {
        return (
            <div className={style.confirmRetake}>
                <p>
                    {item.retakeConfirmMessage ||
                        'It is recommended to download the report' +
                            ' before retaking the assessment. Once you' +
                            ' finish the retake, you will no longer be' +
                            ' able to view previous scores. Do you want' +
                            ' to proceed?'}
                </p>
                <div className={style.btnContainer}>
                    <div>
                        {!isReportVisible && (
                            <Button
                                title={item.retakeAbortButton || 'Download report'}
                                isLoading={isPdfDownloading}
                                onClick={handleClickDownload}
                            />
                        )}
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={item.setCurrent}>
                            {item.retakeConfirmButton || 'Proceed to assessment'}
                        </button>
                    </div>
                </div>
                <div
                    style={{ display: isReportVisible ? 'block' : 'none' }}
                    id="report-container-hidden"
                    className={style.reportWrapper}
                >
                    <ReportRender handle="poiseReport" deploymentId={deploymentId} />
                </div>
            </div>
        );
    }

    return (
        <Modal
            title={isReportVisible ? 'POISE Assessment Report' : 'Are you sure?'}
            size={isReportVisible ? 'large' : 'medium'}
            trigger={button}
            afterClose={() => setIsReportVisible(false)}
        >
            {renderModalBody()}
        </Modal>
    );
};

ConfirmRetake.propTypes = {
    button: PropTypes.node.isRequired,
    item: PropTypes.object.isRequired,
    deploymentId: PropTypes.number,
};

export default ConfirmRetake;
