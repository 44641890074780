import { useEffect, useState } from 'react';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _sortBy from 'lodash/sortBy';
import _union from 'lodash/union';

const flowKeysToOmit = [
    'flowSettings',
    'isComplete',
    'isStarted',
    'progressBarGroup',
    'showIfOption',
    'surveySettings',
];

export default ({
    allowRetake,
    flowList,
    mustTakeInOrder,
    mediatorProps,
    onAlreadyComplete,
    onAssignmentComplete,
    onModuleComplete,
    onComplete,
    onFlowComplete,
    onFlowStarted,
}) => {
    const [completedFlows, setCompletedFlows] = useState(
        flowList.filter(item => item.isComplete).map(item => item.handle)
    );

    const [hasCompleted, setHasCompleted] = useState(
        !flowList.filter(f => f.isRequired).find(f => !f.isComplete)
    );
    const [startedFlows, setStartedFlows] = useState(
        flowList.filter(item => item.isStarted).map(item => item.handle)
    );

    const [nextFlowHandle, setNextFlowHandle] = useState(null);
    const [orderedFlowHandles, setOrderedFlowHandles] = useState([]);

    const [currentFlowHandle, setCurrentFlowHandle] = useState(null);
    const currentFlowFromFlowList = flowList.find(item => item.handle === currentFlowHandle);

    const [isLoading, setIsLoading] = useState(false);
    const [modules, setModules] = useState(mediatorProps.modules);

    const determineStartingModule = () => {
        const incompleteFlows = flowList.filter(f => !f.isComplete && !f.isPoise);
        const firstModule = _map(_sortBy(incompleteFlows, 'moduleId'), 'moduleId');
        if (firstModule.length) {
            return firstModule[0];
        }
        return 1;
    };

    const navItems = flowList.map(flow => {
        let isAllowed = !mustTakeInOrder;
        const isCompleted = completedFlows.includes(flow.handle);
        const isStarted = startedFlows.includes(flow.handle);
        const isResume = !isCompleted && isStarted;

        if (isCompleted || isStarted || flow.handle === nextFlowHandle) {
            isAllowed = true;
        }

        const nav = {
            ..._omit(flow, flowKeysToOmit),
            title: _get(flow, 'flowSettings.playerTemplateSettings.title', flow.handle),
            isCompleted,
            isAllowed,
            isResume,
            isStarted,
            setCurrent: !isAllowed
                ? null
                : () => {
                      setCurrentFlowHandle(flow.handle);
                      setStartedFlows(_union(startedFlows, [flow.handle]).sort());
                  },
        };

        if (nav.isPoise) {
            try {
                let mod = modules.find(m => m.id === nav.moduleId);
                nav.retakeConfirmMessage = mod.poiseRetakeConfirmMessage;
                nav.retakeConfirmButton = mod.poiseRetakeConfirmButton;
                nav.retakeAbortButton = mod.poiseRetakeAbortButton;
                nav.retakeButtonTooltipText = mod.retakeButtonTooltipText;
                nav.poiseQuickReview = mod.poiseQuickReview;
                nav.poiseQuickReviewTitle = mod.poiseQuickReviewTitle;
            } catch (e) {
                // probably not listed in module settings, no need to fuss
            }
        }

        return nav;
    });

    useEffect(() => {
        if (!allowRetake && flowList.every(item => item.isComplete) && onAlreadyComplete) {
            onAlreadyComplete();
        }

        const startingModule = determineStartingModule();
        function packageModules() {
            const packagedModules = modules.map(module => {
                module.setActive = setActiveModuleFunc(module);
                module.isActive = Number.parseInt(module.id, 10) === startingModule;
                module.lessons = getLessonsByModuleId(module.id);
                module.isComplete = module.lessons.every(
                    item => !item.isRequired || completedFlows.includes(item.handle)
                );
                module.isResume =
                    !module.isComplete &&
                    !!module.lessons.find(item => startedFlows.includes(item.handle));
                module.isAllowed =
                    !mustTakeInOrder ||
                    module.lessons.some(
                        l => startedFlows.includes(l.handle) || completedFlows.includes(l.handle)
                    );
                module.isEmpty = module.lessons.length === 0;
                module.showDimmed =
                    (!module.isActive &&
                        !module.isResume &&
                        !module.isComplete &&
                        !module.isAllowed) ||
                    module.isEmpty;
                return module;
            });
            const sortedPackagedModules = _sortBy(packagedModules, 'id');
            setModules(sortedPackagedModules);

            let ofh = [];
            sortedPackagedModules.forEach(module => {
                ofh = ofh.concat(_map(module.lessons, 'handle'));
            });
            setOrderedFlowHandles(ofh);
            checkNextFlowHandle(ofh);
        }

        packageModules();
    }, []);

    useEffect(() => {
        function updateModules() {
            const packagedModules = modules.map(module => {
                module.lessons = getLessonsByModuleId(module.id);
                module.isComplete = module.lessons.every(
                    item => !item.isRequired || completedFlows.includes(item.handle)
                );
                module.isResume =
                    !module.isComplete &&
                    !!module.lessons.find(item => startedFlows.includes(item.handle));
                module.isAllowed = !mustTakeInOrder || module.lessons.some(l => l.isAllowed);
                module.showDimmed =
                    (!module.isActive &&
                        !module.isResume &&
                        !module.isComplete &&
                        !module.isAllowed) ||
                    module.lessons.length === 0;
                return module;
            });
            setModules(packagedModules);
        }

        checkNextFlowHandle(orderedFlowHandles);

        updateModules();
    }, [startedFlows, completedFlows, nextFlowHandle]);

    useEffect(() => {
        if (!currentFlowFromFlowList) {
            return;
        }
        onFlowStarted(currentFlowFromFlowList);
    }, [currentFlowHandle]);

    /**
     * If applicable, set the next flow handle.
     *
     * @param Array ofh The orderedFlowHandles array to consider
     */
    function checkNextFlowHandle(ofh) {
        if (startedFlows.length !== completedFlows.length || startedFlows.length === ofh.length) {
            return;
        }

        if (startedFlows.every(sf => completedFlows.some(cf => cf.handle === sf.handle))) {
            const determinedNextHandle = _difference(ofh, startedFlows)[0];
            if (determinedNextHandle !== nextFlowHandle) {
                setNextFlowHandle(determinedNextHandle);
            }
        }
    }

    function setActiveModuleFunc(module) {
        return () => {
            setModules(
                modules.map(m => {
                    m.isActive = m.id === module.id;
                    return m;
                })
            );
        };
    }

    function getLessonsByModuleId(moduleId) {
        const lessons = [];
        moduleId = Number.parseInt(moduleId, 10);
        navItems.forEach(f => {
            let fModuleId = Number.parseInt(f.moduleId, 10);
            if (
                fModuleId === moduleId ||
                (f.otherModuleIds && f.otherModuleIds.includes(moduleId))
            ) {
                lessons.push(f);
            }
        });

        return lessons.sort((a, b) => {
            // leave the 'poise' flow in it's position
            if (!a.ordinalPosition || !b.ordinalPosition) {
                return 0;
            }
            return a.ordinalPosition - b.ordinalPosition;
        });
    }

    function getCurrentFlowProps() {
        if (!currentFlowHandle) {
            return null;
        }
        const currentFlowIndex = navItems.findIndex(item => item.handle === currentFlowHandle);
        const currentFlow = navItems[currentFlowIndex];

        return {
            ..._omit(currentFlow, flowKeysToOmit),
            ...(currentFlow.isCompleted && { pageFirstElementId: 0 }),
            onComplete: () => {
                completeCurrentFlow();
            },
        };
    }

    async function completeCurrentFlow() {
        setIsLoading(true);
        const currModule = modules.find(item => item.isActive);
        const isFirstCompletion = currentFlowFromFlowList.isComplete === false;

        await onFlowComplete(currentFlowFromFlowList, {
            isFirstCompletion,
            moduleId: currModule.id,
        });

        const updatedCompletedFlows = _union(completedFlows, [currentFlowHandle]);

        const requiredFlows = flowList.filter(item => item.isRequired);
        if (
            !hasCompleted &&
            requiredFlows.every(item => updatedCompletedFlows.includes(item.handle))
        ) {
            if (onAssignmentComplete) {
                await onAssignmentComplete();
            }
            setHasCompleted(true);
        }

        if (updatedCompletedFlows.length === flowList.length && onComplete) {
            await onComplete();
        }

        if (
            !currModule.isComplete &&
            currModule.lessons.every(
                item => !item.isRequired || updatedCompletedFlows.includes(item.handle)
            )
        ) {
            if (onModuleComplete) {
                await onModuleComplete({ moduleId: currModule.id, isFirstCompletion });
            }
            currModule.isComplete = true;
        }

        setCompletedFlows(updatedCompletedFlows.sort());
        setCurrentFlowHandle(null);
        setIsLoading(false);
    }

    return {
        closeFlowPlayer: (isCompletingPoise = false) => {
            // if finishing POISE, we need to reset isStarted back to false for the next deployment
            if (isCompletingPoise) {
                setStartedFlows(startedFlows.filter(handle => handle !== currentFlowHandle));
            }
            setCurrentFlowHandle(null);
        },
        currentFlowProps: getCurrentFlowProps(),
        modules,
        isLoading,
    };
};
