import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { client } from 'cccisd-apollo';
import Warning from 'cccisd-icons/warning2';
import { Page } from 'cccisd-laravel-report';
import PhysicalTab from './images/PhysicalCropped.png';
import OccupationalTab from './images/OccupationalCropped.png';
import IntellectualTab from './images/IntellectualCropped.png';
import SocialTab from './images/SocialCropped.png';
import EmotionalTab from './images/EmotionalCropped.png';
import smartDataQuery from './smartData.graphql';
import style from './style.css';

const goalTags = {
    physical: [
        'smart_goal_p_1',
        'smart_goal_p_2',
        'smart_goal_p_3',
        'smart_goal_p_4',
        'smart_goal_p_5',
    ],
    occupational: [
        'smart_goal_o_1',
        'smart_goal_o_2',
        'smart_goal_o_3',
        'smart_goal_o_4',
        'smart_goal_o_5',
    ],
    intellectual: [
        'smart_goal_i_1',
        'smart_goal_i_2',
        'smart_goal_i_3',
        'smart_goal_i_4',
        'smart_goal_i_5',
    ],

    social: [
        'smart_goal_s_1',
        'smart_goal_s_2',
        'smart_goal_s_3',
        'smart_goal_s_4',
        'smart_goal_s_5',
    ],

    emotional: [
        'smart_goal_e_1',
        'smart_goal_e_2',
        'smart_goal_e_3',
        'smart_goal_e_4',
        'smart_goal_e_5',
    ],
};

const fakeData = {
    smart_goal_p_1:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostru',
    smart_goal_p_2: 'smart_goal_p_2',
    smart_goal_p_3: 'smart_goal_p_3',
    smart_goal_p_4: '',
    smart_goal_p_5: 'smart_goal_p_5',
    smart_goal_o_1: 'smart_goal_o_1',
    smart_goal_o_2: '',
    smart_goal_o_3: 'smart_goal_o_3',
    smart_goal_o_4: '',
    smart_goal_o_5: 'smart_goal_o_5',
    smart_goal_i_1: 'smart_goal_i_1',
    smart_goal_i_2: 'smart_goal_i_2',
    smart_goal_i_3: 'smart_goal_i_3',
    smart_goal_i_4: 'smart_goal_i_4',
    smart_goal_i_5: 'smart_goal_i_5',
    smart_goal_s_1: 'smart_goal_s_1',
    smart_goal_s_2: 'smart_goal_s_2',
    smart_goal_s_3: 'smart_goal_s_3',
    smart_goal_s_4: 'smart_goal_s_4',
    smart_goal_s_5: 'smart_goal_s_5',
    smart_goal_e_1: '',
    smart_goal_e_2: '',
    smart_goal_e_3: '',
    smart_goal_e_4: '',
    smart_goal_e_5: '',
};

const Fortress = window.cccisd.fortress;
// Wrap at 160 chars

const ReportTable = props => {
    const widgetProps = props.props;
    const [data, setData] = useState(
        widgetProps.isPreview ? fakeData : widgetProps.mostRecentSmartGoals
    );

    useEffect(() => {
        async function getSmartData() {
            const response = await client.query({
                query: smartDataQuery,
                variables: {
                    pawnId: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });
            const smartData = _get(response, 'data.flows.assignmentProgress.devTags', {});
            Object.keys(smartData).forEach(k => {
                if (!(k in fakeData)) {
                    delete smartData[k];
                } else if (!smartData[k]) {
                    smartData[k] = '';
                }
            });
            setData(smartData);
        }
        if (!data) {
            getSmartData();
        }
    }, []);

    const getSectionData = section => {
        const sectionTags = goalTags[section];
        let values = [];

        sectionTags.forEach((item, i) => {
            const text = data[item];
            if (text && text !== '') {
                values.push({ text });
            } else {
                values.push({});
            }
        });

        const compare = (a, b) => {
            if (!_isEmpty(a) && _isEmpty(b)) {
                return -1;
            }
            if (_isEmpty(a) && !_isEmpty(b)) {
                return 1;
            }
            return 0;
        };

        return values.sort(compare);
    };

    const getData = () => {
        const physicalData = getSectionData('physical');
        const occupationalData = getSectionData('occupational');
        const intellectualData = getSectionData('intellectual');
        const socialData = getSectionData('social');
        const emotionalData = getSectionData('emotional');

        return [
            { section: 'physical', rows: physicalData },
            { section: 'occupational', rows: occupationalData },
            { section: 'intellectual', rows: intellectualData },
            { section: 'social', rows: socialData },
            { section: 'emotional', rows: emotionalData },
        ];
    };

    const renderSectionRows = section => {
        const { rows } = section;
        const sectionLabels = {
            physical: PhysicalTab,
            occupational: OccupationalTab,
            intellectual: IntellectualTab,
            social: SocialTab,
            emotional: EmotionalTab,
        };
        const borderColor = {
            physical: '#D12322',
            occupational: '#F4722B',
            intellectual: '#FFB047',
            social: '#59A643',
            emotional: '#5D69D8',
        };
        const noAnswers = rows.every(item => _isEmpty(item));

        return (
            <React.Fragment key={section.section}>
                {rows.map((row, i) => {
                    const hasRowText = row.text && row.text !== '';
                    return (
                        <tr className={i === 4 ? style.tableRowLast : style.tableRow} key={i}>
                            {i === 0 && (
                                <td
                                    className={style.imageCell}
                                    style={{
                                        borderLeft: `2px solid ${borderColor[section.section]}`,
                                        borderTop: `2px solid ${borderColor[section.section]}`,
                                        borderBottom: `2px solid ${borderColor[section.section]}`,
                                    }}
                                    rowSpan={5}
                                >
                                    <img
                                        src={sectionLabels[section.section]}
                                        alt={section.section}
                                    />
                                </td>
                            )}
                            <td
                                className={style.rowText}
                                colSpan={5}
                                style={{
                                    borderTop:
                                        i === 0 && `2px solid ${borderColor[section.section]}`,
                                    borderRight: `2px solid ${borderColor[section.section]}`,
                                    borderBottom:
                                        i === 4 && `2px solid ${borderColor[section.section]}`,
                                }}
                            >
                                <div className={style.rowDiv}>
                                    {i === 2 && noAnswers ? (
                                        <div style={{ textAlign: 'center', paddingTop: '5px' }}>
                                            <Warning /> No SMART goal set
                                        </div>
                                    ) : (
                                        <span>
                                            {hasRowText && <span>&#8226;</span>} {row.text}{' '}
                                        </span>
                                    )}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </React.Fragment>
        );
    };
    const renderSectionTable = section => {
        return (
            <table className={style.reportTable}>
                <thead>
                    <tr>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <React.Fragment key={section.section}>
                        {renderSectionRows(section)}
                    </React.Fragment>
                </tbody>
            </table>
        );
    };
    const renderBottom = number => {
        return (
            <div style={{ marginTop: `${number === 1 ? '40px' : '420px'}` }}>
                {props.renderFooter()}
                <div style={{ textAlign: 'center', fontSize: '10pt', color: '#b5b5b5' }}>
                    Page {number} of 2
                </div>
            </div>
        );
    };
    const renderTables = page => {
        const tableData = getData();
        if (page === 1) {
            return (
                <Page>
                    {props.renderHeader()}
                    {tableData.map((item, i) => {
                        if (i < 3) {
                            return (
                                <div className={style.sectionTable} key={i}>
                                    {renderSectionTable(item)}
                                </div>
                            );
                        }
                        return null;
                    })}
                    {renderBottom(1)}
                </Page>
            );
        }
        return (
            <Page>
                {tableData.map((item, i) => {
                    if (i >= 3) {
                        return (
                            <div className={style.sectionTable} key={i}>
                                {renderSectionTable(item)}
                            </div>
                        );
                    }
                    return null;
                })}
                {renderBottom(2)}
            </Page>
        );
    };

    // Report height decided on first render,
    // so always return two pages
    if (!data) {
        return (
            <>
                <Page />
                <Page />
            </>
        );
    }

    return (
        <>
            {renderTables(1)} {renderTables(2)}
        </>
    );
};

ReportTable.propTypes = {
    props: PropTypes.object,
    renderFooter: PropTypes.func,
    renderHeader: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
ReportTable.defaultProps = {
    props: {},
};

export default ReportTable;
