import React from 'react';
import { useLocation } from 'cccisd-react-router';
import Navbar from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;
const wpUrl = window.cccisd.env.wpUrl;

export default () => {
    const { pathname } = useLocation();

    const actingRole = Fortress.user.acting.role.handle;
    const primaryNavLinks = [{ url: '/manage', label: 'Manage' }];
    const primaryNavLinks2 = [
        { url: '/manage', label: 'Manage' },
        { url: '/progress', label: 'Progress' },
        { url: '/data', label: 'Data' },
        { url: '/logs', label: 'Logs' },
        {
            url: '/resources',
            label: 'Resources',
            children: [
                { url: '/resources/admin', label: 'Admin' },
                { url: '/teacherwiseresources', label: 'TeacherWISE Resource Center' },
            ],
        },
    ];

    // don't let learner bypass the site registration DCW with navigation in the header
    const primaryNavLinksLearner =
        pathname === '/account/registrationSurvey' ? [] : [{ url: '/survey/course', label: 'Course' }];

    return (
        <>
            {actingRole === 'uberadmin' && (
                <Navbar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    primaryNavLinks={primaryNavLinks}
                    logoLink={wpUrl}
                />
            )}
            {actingRole === 'siteAdmin' && (
                <Navbar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    primaryNavLinks={primaryNavLinks2}
                    logoLink={wpUrl}
                />
            )}
            {actingRole === 'guest' && (
                <Navbar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    logoLink={wpUrl}
                />
            )}
            {actingRole === 'learner' && (
                <Navbar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    primaryNavLinks={primaryNavLinksLearner}
                    logoLink={wpUrl}
                />
            )}
        </>
    );
};
