import React from 'react';
import PropTypes from 'prop-types';
import widgets from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import style from './style.css';
import HeaderLogo from './images/WISE_Logo.png';

import format from 'date-fns/format';

const date = format(new Date(), 'MM-dd-yyyy');

const renderHeader = title => {
    return (
        <>
            <div className={style.reportHeader}>
                <img src={HeaderLogo} alt="headerLogo" width="300px" height="62px" />
                <div className={style.title}>{title}</div>
                <div className={style.date}>{date}</div>
            </div>
            <hr />
        </>
    );
};

const Component = props => {
    const { tableReport } = props.widgets;
    return <>{tableReport}</>;
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    getWidgets: props => {
        return widgets.map(w => ({
            ...w,
            widgetProps: {
                ...props,
                renderHeader: () => {
                    return <>{renderHeader(props.settings.title)}</>;
                },
            },
        }));
    },
    filterFields: [],
})(Component);
