import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { Page, Report, BrowserOnly, PrintOnly } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import moment from 'moment-timezone';
import style from './style.css';

import certificateQuery from './certificateQuery.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Cccisd = window.cccisd;
const Fortress = window.cccisd.fortress;

const DATE_FORMAT = 'MMMM D, YYYY';

const Certificate = ({ actingPawnHash, actingPawnId, deploymentId, hide, language, settings }) => {
    let defaultCertificateId = '–';
    const certData = _get(settings, `staticCertificateId.${Fortress.user.acting.data_type}`);
    if (certData) {
        defaultCertificateId = _get(Cccisd, certData.path) || '–';
    }

    // to prevent breaking changes, if settings doesn't contain `certificates`
    // key, convert from old to new
    const certificates = settings.certificates || [
        {
            backgroundId: settings.backgroundId,
            courseName: settings.courseName,
            freeText: settings.freeText,
        },
    ];

    const [backgroundUrls, setBackgroundUrls] = useState({});
    const [certificateId, setCertificateId] = useState(defaultCertificateId);
    const [formattedDate, setFormattedDate] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true);

    const defaultName = 'Full Name Here';

    /* /////////////////////////////////////////////////////////////////////////
    // LIFECYLE-RELATED ///////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    useEffect(() => {
        async function getBackgroundUrl(bgId, returnObj) {
            const baseUrl = Boilerplate.route('api.resources.file.index');
            const response = await axios.get(`${baseUrl}?id=${bgId}`);
            if (response.status === 200 && response.data.status === 'success') {
                returnObj[bgId] = response.data.data[0].published_url;
            }
        }

        async function getCertificateDateAndUser() {
            if (!actingPawnId || !language || !deploymentId) {
                const actingUser = Fortress.user.acting.user || {};
                const altName = actingUser.full_name || actingUser.username || defaultName;
                setName(altName);
                setFormattedDate(moment().format(DATE_FORMAT));
                return;
            }
            const result = await apollo.query({
                query: certificateQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentId,
                    language,
                    actingPawnHash,
                    actingPawnId,
                },
            });

            const ap = result.data.flows.assignmentProgress;
            if (ap.completedDate) {
                setFormattedDate(moment.utc(ap.completedDate).format(DATE_FORMAT));
            } else {
                setFormattedDate(moment().format(DATE_FORMAT));
            }
            const user = ap.pawn.user;
            setName(user.fullName || user.username || defaultName);
            if (!certData) {
                setCertificateId(ap.assignmentProgressId + 1000000);
            }
        }

        async function loadData() {
            if (!hide) {
                const loadingPromises = [];
                const startingBackgroundIds = certificates
                    .filter(c => c.backgroundId)
                    .map(c => c.backgroundId);
                const localBackgroundUrls = {};
                if (Object.keys(backgroundUrls).length === 0 && startingBackgroundIds.length > 0) {
                    const backgroundIdsPromised = [];
                    startingBackgroundIds.forEach(bgId => {
                        if (!backgroundIdsPromised.includes(bgId)) {
                            backgroundIdsPromised.push(bgId);
                            loadingPromises.push(getBackgroundUrl(bgId, localBackgroundUrls));
                        }
                    });
                }
                loadingPromises.push(getCertificateDateAndUser());
                await Promise.all(loadingPromises);

                setBackgroundUrls(localBackgroundUrls);
            }

            setLoading(false);
        }

        loadData();
    }, [hide]);

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    const content = ({ accreditations, certificate, showAccreditation, viewType = 'browser' }) => {
        const containerStyle =
            viewType === 'browser'
                ? style.certificateContainerBrowser
                : style.certificateContainerPrint;
        return (
            <div className={containerStyle}>
                <div
                    className={style.certificate}
                    style={{ backgroundImage: `url(${backgroundUrls[certificate.backgroundId]})` }}
                >
                    <div className={style.passDate}>{formattedDate}</div>
                    <div className={style.certificateContent}>
                        <div className={style.name}>{name}</div>
                        <div className={style.courseName}>{certificate.courseName}</div>
                        {certificate.freeText && <Html content={certificate.freeText} />}
                        <div className={style.certificateId}>
                            <b>{certData && certData.label ? certData.label : 'Certificate ID:'}</b>{' '}
                            {certificateId}
                        </div>
                    </div>
                    {showAccreditation && (
                        <div className={style.accreditationContainer}>
                            {accreditations.map((aText, i) => (
                                <div key={i} className={style.accreditation}>
                                    <Html content={aText} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const downloadFilename = `Certificate_${certificateId}`
        .replace(/[ -]/g, '_')
        .replace(/[^A-Za-z0-9_]/g, '')
        .replace(/_$/, '');

    const renderCompletionText = !settings.completionText ? null : (
        <div className={style.completionText}>
            <Html content={settings.completionText} />
        </div>
    );

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    if (hide) {
        return (
            <div>
                You must first complete all requirements before accessing the {settings.tabName}.
            </div>
        );
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <React.Fragment>
            {renderCompletionText}
            <Report downloadFilename={downloadFilename}>
                {certificates.map((certificate, index) => {
                    const accreditations = [
                        certificate.accreditation1,
                        certificate.accreditation2,
                        certificate.accreditation3,
                    ].filter(a => a);
                    const showAccreditation = accreditations.length > 0;
                    return (
                        <Page key={index}>
                            <BrowserOnly>
                                {content({
                                    accreditations,
                                    certificate,
                                    showAccreditation,
                                    viewType: 'browser',
                                })}
                            </BrowserOnly>
                            <PrintOnly>
                                {content({
                                    accreditations,
                                    certificate,
                                    showAccreditation,
                                    viewType: 'print',
                                })}
                            </PrintOnly>
                        </Page>
                    );
                })}
            </Report>
        </React.Fragment>
    );
};

Certificate.propTypes = {
    actingPawnHash: PropTypes.string,
    actingPawnId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deploymentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hide: PropTypes.bool,
    language: PropTypes.string,
    settings: PropTypes.object,
};

export default Certificate;
