import thumbnail from './thumbnail.png';
import Settings from './Settings';
import Mediator from './Mediator';

export default {
    handle: 'modulePoise',
    label: 'POISE With Modules',
    thumbnail,
    settingsComponent: Settings,
    mediatorComponent: Mediator,
};
