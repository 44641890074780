import React, { useState, useEffect } from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const PoiseGraph = ({ devTags, recommendedModules, starIcon, height }) => {
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 640);

    useEffect(() => {
        function checkOnResize() {
            if (window.innerWidth < 640 && !isScreenSmall) {
                setIsScreenSmall(true);
            } else if (window.innerWidth >= 640 && isScreenSmall) {
                setIsScreenSmall(false);
            }
        }

        window.addEventListener('resize', checkOnResize);
        return () => window.removeEventListener('resize', checkOnResize);
    }, [isScreenSmall]);

    function renderStar(barProps) {
        // starts at top point, draws lines to the right
        // then mirrors over y-axis
        const hasDecimal = parseInt(barProps.label, 10) !== parseFloat(barProps.label);
        let startX = barProps?.bar?.width / 2 + 8;
        if (hasDecimal && !isScreenSmall) {
            startX = barProps?.bar?.width / 2 + 19;
        }

        const startY = barProps?.bar?.height / 2 - 11;
        return (
            <path
                d={`
                    M ${startX} ${startY}

                    L ${startX + 1.4} ${startY + 3}
                    L ${startX + 4} ${startY + 3.1}
                    L ${startX + 2.2} ${startY + 5.1}
                    L ${startX + 3} ${startY + 8}

                    L ${startX} ${startY + 6.5}

                    L ${startX - 3} ${startY + 8}
                    L ${startX - 2.2} ${startY + 5.1}
                    L ${startX - 4} ${startY + 3.1}
                    L ${startX - 1.4} ${startY + 3}

                    Z
                `}
                stroke="white"
                fill="white"
            />
        );
    }

    function CustomBar(barProps) {
        const category = barProps.bar.data.id;
        let isStar = !!recommendedModules.find(m => m.devTag.startsWith(`poise_${category[0]}`));
        return (
            <g transform={`translate(${barProps.bar.x},${barProps.bar.y})`}>
                <rect
                    width={barProps.bar.width}
                    height={barProps.bar.height}
                    fill={barProps?.bar?.data?.fill ? barProps?.bar?.data?.fill : barProps?.bar?.color}
                />
                {barProps.shouldRenderLabel && (
                    <text
                        x={isStar ? barProps?.bar?.width / 2 - 4.5 : barProps?.bar?.width / 2}
                        y={barProps?.bar?.height / 2}
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                            fontSize: isScreenSmall ? 14 : 24,
                            fontFamily: 'sans-serif',
                            fill: 'rgb(255, 255, 255)',
                        }}
                    >
                        {barProps.label}
                    </text>
                )}
                {isStar && renderStar(barProps)}
            </g>
        );
    }

    let generalProps = {
        colors: { scheme: 'category10' },
        borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
        labelTextColor: '#FFFFFF',
        animate: true,
        motionStiffness: 90,
        motionDamping: 15,
        margin: {
            top: isScreenSmall ? 10 : 20,
            right: 50,
            bottom: (() => {
                let margin = isScreenSmall ? 90 : 50;
                if (recommendedModules.length > 0) {
                    // need space for legend
                    margin += 40;
                }
                return margin;
            })(),
            left: 50,
        },
        minValue: 0,
        maxValue: 5,
    };
    const chartData = [
        { area: 'Physical', physical: parseFloat(devTags.poise_p_average), color: '#D12321' },
        {
            area: 'Occupational',
            occupational: parseFloat(devTags.poise_o_average),
            color: '#F4712B',
        },
        {
            area: 'Intellectual',
            intellectual: parseFloat(devTags.poise_i_average),
            color: '#FFB047',
        },
        { area: 'Social', social: parseFloat(devTags.poise_s_average), color: '#5AA643' },
        { area: 'Emotional', emotional: parseFloat(devTags.poise_e_average), color: '#5D6AD8' },
    ];
    const chartProps = {
        keys: ['physical', 'occupational', 'intellectual', 'social', 'emotional'],
        indexBy: 'area',
        axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: isScreenSmall ? 45 : 0,
        },
        axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: [0, 1, 2, 3, 4, 5],
        },
        gridYValues: [0, 1, 2, 3, 4, 5],
        legends: [],
        tooltip: () => null,
        colors: ({ data: { color } }) => color,
        barComponent: CustomBar,
        theme: {
            axis: {
                ticks: {
                    text: {
                        fontSize: 15,
                        fontWeight: 100,
                    },
                },
            },
            labels: {
                text: {
                    fontSize: isScreenSmall ? 12 : 24,
                },
            },
        },
    };

    return (
        <div className={style.poiseGraph} style={height && { height }}>
            <ResponsiveBar {...generalProps} {...chartProps} data={chartData} />
            {recommendedModules.length > 0 && (
                <span className={style.legend}>
                    <span className={style.icon}>{starIcon}</span>&nbsp; Recommended focus
                </span>
            )}
        </div>
    );
};

PoiseGraph.propTypes = {
    devTags: PropTypes.object.isRequired,
    recommendedModules: PropTypes.array.isRequired,
    starIcon: PropTypes.node,
    height: PropTypes.number,
};

export default PoiseGraph;
