import React, { useState, useEffect } from 'react';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import DefaultMediator from 'cccisd-laravel-assignment/dist/components/Mediator';

const CourseRegistrationMediator = props => {
    const {
        flowList,
        onComplete,
        isLoading,
        currentPoiseDeployment,
        mostRecentPoiseData,
        onPoiseComplete,
        isPreviewMode,
        isNetworkError,
    } = props;
    const getShouldPlayPoise = () =>
        !!(
            !mostRecentPoiseData &&
            flowList.find(
                (flow, index) =>
                    (flow.surveySettings.playPoiseAfter &&
                        flow.isComplete &&
                        flowList[index + 1] && // prevent index error at end of list
                        !flowList[index + 1].isStarted) ||
                    flowList.every(f => f.isComplete)
            )
        );

    const [isPlayingPoise, setIsPlayingPoise] = useState(getShouldPlayPoise());
    const [isPoiseCompleteLocal, setIsPoiseCompleteLocal] = useState(false);
    useEffect(() => {
        setIsPlayingPoise(getShouldPlayPoise());
    }, [flowList, mostRecentPoiseData]);

    function handlePoiseComplete() {
        setIsPoiseCompleteLocal(true);
        onPoiseComplete();
    }

    if (isPlayingPoise && !isPoiseCompleteLocal) {
        return (
            <Loader loading={!currentPoiseDeployment} removeChildren>
                {currentPoiseDeployment && (
                    <DeploymentPlayer
                        deploymentId={currentPoiseDeployment.deploymentId}
                        onComplete={handlePoiseComplete}
                        disableLayout
                        isPreviewMode={isPreviewMode}
                    />
                )}
            </Loader>
        );
    }

    let playPoiseAfterIndex = flowList.findIndex(flow => {
        return flow.surveySettings.playPoiseAfter;
    });
    // If flag is not specified, all surveys are pre-poise
    if (playPoiseAfterIndex === -1) {
        console.error('Should add a key "playPoiseAfter" to the settings of one survey.');
        playPoiseAfterIndex = flowList.length - 1;
    }

    const prePoiseFlows = flowList.slice(0, playPoiseAfterIndex + 1);
    const postPoiseFlows = flowList.slice(playPoiseAfterIndex + 1);
    // We know whether to use prePoise or postPoise based on whether there is progress data
    let flowsToUse = prePoiseFlows;
    if (mostRecentPoiseData || isPoiseCompleteLocal || (isPreviewMode && postPoiseFlows.find(f => f.isStarted))) {
        flowsToUse = postPoiseFlows;
    }

    function handleFlowListComplete() {
        if (flowsToUse === prePoiseFlows) {
            setIsPlayingPoise(true);
        } else {
            onComplete();
        }
    }

    if (isPreviewMode && flowsToUse.every(f => f.isComplete)) {
        handleFlowListComplete();
    }

    const mediatorProps = {
        ...props,
        flowList: flowsToUse,
        onComplete: handleFlowListComplete,
    };

    return (
        <Loader loading={isLoading} removeChildren={!isNetworkError}>
            <div>
                <DefaultMediator {...mediatorProps} isNetworkError={isNetworkError} />
            </div>
        </Loader>
    );
};

export default CourseRegistrationMediator;
