import React, { useState } from 'react';
import classnames from 'classnames';
import ArrowDown from 'cccisd-icons/arrow-down20';
import ArrowRight from 'cccisd-icons/arrow-right20';
import ReportIcon from 'cccisd-icons/file-text2';
import StarIcon from 'cccisd-icons/star-full2';
import IconCheckmark from 'cccisd-icons/svg/checkmark';
import IconLock from 'cccisd-icons/svg/lock4';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import { Player, DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import { Html } from 'cccisd-wysiwyg';
import LessonCard from './components/LessonCard';
import ReportRender from './components/ReportRender';
import ConfirmRetake from './components/ConfirmRetake';
import PoiseGraph from './PoiseGraph';
import { initialValues as initialSettings } from '../Settings';
import useWithModulesMediator from './useWithModulesMediator';
import style from './style.css';
import IconClock from 'cccisd-icons/clock';

// MODULE ICONS //
import IntroductionIcon from '../../../../images/moduleIcons/Introduction.png';
import PhysicalIcon from '../../../../images/moduleIcons/Physical.png';
import OccupationalIcon from '../../../../images/moduleIcons/Occupational.png';
import IntellectualIcon from '../../../../images/moduleIcons/Intellectual.png';
import SocialIcon from '../../../../images/moduleIcons/Social.png';
import EmotionalIcon from '../../../../images/moduleIcons/Emotional.png';
import MindfulnessIcon from '../../../../images/moduleIcons/Mindfulness.png';
import SmartIcon from '../../../../images/moduleIcons/Smart.png';
const moduleIcons = [
    IntroductionIcon,
    PhysicalIcon,
    OccupationalIcon,
    IntellectualIcon,
    SocialIcon,
    EmotionalIcon,
    MindfulnessIcon,
    SmartIcon,
];

const translate = {
    en: {
        moduleText: 'MODULE',
        overview: 'Overview',
    },
    es: {
        moduleText: 'MÓDULO',
        overview: 'El Resumen',
    },
};

const Fortress = window.cccisd && window.cccisd.fortress;

const PoiseWithModulesMediator = props => {
    if (props.isLoading) {
        // get poise deployment data before doing any module/lesson logic
        return null;
    }
    const [isPoiseGraphCollapsed, setIsPoiseGraphCollapsed] = useState(false);
    const [isPoiseGraphSliding, setIsPoiseGraphSliding] = useState(false);

    let {
        mediatorProps,
        mostRecentPoiseData,
        mostRecentSmartGoals,
        currentPoiseDeployment,
        isPreviewMode,
        onPoiseComplete,
        onSmartComplete,
        flowList,
        isNetworkError,
    } = props;
    let { devTags } = mostRecentPoiseData;

    // Add POISE flow to flowList (if it hasn't already been added)
    let updatedFlowList = [...flowList];
    if (currentPoiseDeployment && flowList.length === props.flowList.length) {
        let poiseModuleId = 1;
        let poiseIndex =
            flowList.findIndex(flow => {
                if (flow.playPoiseAfter) {
                    poiseModuleId = flow.moduleId;
                    return true;
                }
                return false;
            }) + 1;

        let poiseFlow = {
            isPoise: true,
            flowId: currentPoiseDeployment.flowId,
            handle: currentPoiseDeployment.flowHandle,
            isComplete: true,
            isStarted: currentPoiseDeployment.isStarted,
            isPreviewMode,
            isRequired: true,
            language: 'en',
            pawnHash: Fortress.user.acting.random_hash,
            pawnId: Fortress.user.acting.id,
            moduleId: poiseModuleId,
            flowSettings: currentPoiseDeployment.settings,
            isAllowed: true,
        };
        updatedFlowList.splice(poiseIndex, 0, poiseFlow);
    }

    const language = Object.keys(translate).includes(props.language) ? props.language : 'en';
    const settings = {
        ...initialSettings,
        ...mediatorProps,
    };

    const { mustTakeInOrder } = settings;

    const { closeFlowPlayer, currentFlowProps, modules, isLoading } = useWithModulesMediator({
        ...props,
        flowList: updatedFlowList,
        mustTakeInOrder,
    });

    const currentModule = modules.find(m => m.isActive);
    const currentModuleIndex = modules.indexOf(currentModule);

    let recommendedModules = [];
    Object.keys(devTags)
        .sort((a, b) => parseFloat(devTags[a]) - parseFloat(devTags[b]))
        .forEach(tag => {
            let score = parseFloat(devTags[tag]);
            if (Number.isNaN(score)) {
                score = 0;
            }

            if (score < 3 && recommendedModules.length < 2) {
                let module = modules.find(m => m.devTag === tag);
                if (module && !module.isComplete) {
                    recommendedModules.push(module);
                }
            }
        });

    function renderPlayerView() {
        if (!currentFlowProps) {
            return null;
        }

        const { isPoise } = currentFlowProps;
        return (
            <>
                <div className={style.breadcrumbs}>
                    {currentModule.title} &nbsp;&rang; {currentFlowProps.title}
                </div>
                <span
                    className={classnames('pull-right', style.closeButton)}
                    onClick={() => {
                        if (isPoise) {
                            // update currentPoiseDeployment in the background (sets isStarted to true)
                            onPoiseComplete(); // updates both POISE and SMART data
                        } else {
                            onSmartComplete(); // only updates SMART data
                        }
                        closeFlowPlayer();
                    }}
                >
                    &times;
                </span>
                <div className={style.flow}>
                    {isPoise ? (
                        <DeploymentPlayer
                            deploymentId={currentPoiseDeployment.deploymentId}
                            disableLayout
                            onComplete={() => {
                                // hide graph to prevent flicker when poise data updates
                                setIsPoiseGraphCollapsed(true);
                                onPoiseComplete(); // sets both POISE and SMART data
                                closeFlowPlayer(true);
                            }}
                            isPreviewMode={isPreviewMode}
                        />
                    ) : (
                        <Player
                            key={currentFlowProps.handle}
                            {...Object.assign({}, currentFlowProps, {
                                onComplete: () => {
                                    onSmartComplete();
                                    currentFlowProps.onComplete();
                                },
                            })}
                            isNetworkError={isNetworkError}
                        />
                    )}
                </div>
            </>
        );
    }

    function renderModuleView() {
        if (currentFlowProps || !currentModule) {
            return null;
        }
        return (
            <>
                {renderPoiseResults()}
                <div className={`${style.wrapper} ${style.boxShadow}`}>
                    <div className={style.sidebar}>{renderModuleNav()}</div>
                    <div className={style.content}>
                        {renderHeader()}
                        {renderLessonArea()}
                    </div>
                </div>
            </>
        );
    }

    function poiseGraphLanguage() {
        let defaultText =
            'The POISE Self-Assessment Scale evaluates five areas of well-being. Lower scores ' +
            'indicate areas that may need the most attention. The two uncompleted modules ' +
            'with scores below 3 are recommended until all the modules have been completed. ' +
            'All five modules must be finished in order to complete the program.';

        const configText = modules.find(m => m.id === 1);
        return (configText && configText.poiseGraphLanguage) || defaultText;
    }

    function retakePoiseButton() {
        let poiseLesson;
        try {
            poiseLesson = modules.find(m => m.id === 1).lessons.find(l => l.isPoise);
        } catch (e) {
            console.error('Could not load POISE lesson. Check configuration.');
            poiseLesson = { setCurrent: () => {} };
        }

        let button = (
            <button
                type="button"
                className={`btn btn-xs btn-${poiseLesson.isStarted ? 'primary' : 'info'} ${style.retakeButton}`}
            >
                {poiseLesson.isStarted ? 'Resume' : 'Retake'}
            </button>
        );

        if (poiseLesson.retakeButtonTooltipText) {
            button = <Tooltip title={poiseLesson.retakeButtonTooltipText}>{button}</Tooltip>;
        }

        return (
            <ConfirmRetake
                button={button}
                item={poiseLesson}
                deploymentId={mostRecentPoiseData.deployment.deploymentId}
            />
        );
    }

    function renderPoiseResults() {
        return (
            <div className={`${style.poiseResults} ${style.boxShadow}`}>
                <h3>POISE Results</h3>
                <p className={style.recommendedExplanation}>{poiseGraphLanguage()}</p>
                {recommendedModules.length > 0 && (
                    <p>
                        Recommended: <b>{recommendedModules.map(m => m.title).join(', ')}</b>
                    </p>
                )}
                <div className={`${style.results} ${isPoiseGraphCollapsed && style.collapsed}`}>
                    <div className={style.buttonRow}>
                        <button
                            type="button"
                            className={'btn btn-link ' + style.collapseButton}
                            onClick={() => {
                                setIsPoiseGraphCollapsed(!isPoiseGraphCollapsed);
                                setIsPoiseGraphSliding(true);
                                setTimeout(() => {
                                    setIsPoiseGraphSliding(false);
                                }, 500);
                            }}
                        >
                            {isPoiseGraphCollapsed ? (
                                <>
                                    <ArrowRight />
                                    &nbsp;&nbsp;Expand POISE results
                                </>
                            ) : (
                                <>
                                    <ArrowDown />
                                    &nbsp;&nbsp;Collapse
                                </>
                            )}
                        </button>
                        <span>
                            {retakePoiseButton()}
                            <Modal
                                trigger={
                                    <button type="button" className="btn btn-default btn-xs">
                                        <ReportIcon />
                                        &nbsp;&nbsp;Report
                                    </button>
                                }
                                size="large"
                                title="POISE Report"
                            >
                                <ReportRender
                                    handle="poiseReport"
                                    deploymentId={mostRecentPoiseData.deployment.deploymentId}
                                />
                            </Modal>
                        </span>
                    </div>
                    {(!isPoiseGraphCollapsed || isPoiseGraphSliding) && (
                        <div className={style.graphContainer}>
                            <PoiseGraph
                                devTags={devTags}
                                recommendedModules={recommendedModules}
                                starIcon={<StarIcon />}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function getModuleHeaderText(module) {
        return 'header' in module ? module.header : translate[language].moduleText + ' ' + module.id;
    }

    function renderModuleNav() {
        return (
            <div className={style.moduleNav}>
                {modules.map(module => {
                    if (module.isEmpty) {
                        return null;
                    }

                    let isRecommended = recommendedModules.some(m => m.id === module.id);
                    return (
                        <div
                            key={module.id}
                            className={classnames(style.item, {
                                [style.current]: module.isActive,
                                [style.notStarted]: module.showDimmed,
                                [style.beforeActive]: modules.indexOf(module) === currentModuleIndex - 1,
                            })}
                            onClick={module.lessons.length > 0 && module.isAllowed ? module.setActive : () => {}}
                        >
                            <p
                                className={classnames(
                                    style.moduleHeader,
                                    module.showDimmed ? null : style.moduleHeaderActive
                                )}
                            >
                                {isRecommended && (
                                    <span className={style.star}>
                                        <StarIcon />
                                        &nbsp;&nbsp;
                                    </span>
                                )}
                                {getModuleHeaderText(module)}
                            </p>
                            <p className={style.moduleTitle}>{module.title}</p>
                            {module.isComplete && !module.showDimmed && (
                                <div className={classnames(style.moduleIcon, style.checkmark)}>
                                    <IconCheckmark />
                                </div>
                            )}
                            {module.showDimmed && (
                                <div className={style.moduleIcon}>
                                    <IconLock />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderHeader() {
        // we're assuming module ID's are integers and sequential
        const iconSrc = moduleIcons[currentModule.id - 1];

        return (
            <div className={style.header}>
                <div>
                    <p className={style.headerModule}>{getModuleHeaderText(currentModule)}</p>
                    <p className={style.headerTitle}>{currentModule.title}</p>
                </div>
                {iconSrc && <img src={iconSrc} className={style.moduleIcon} alt="icon" />}
            </div>
        );
    }

    function renderOverview() {
        if (!currentModule.overview) {
            return null;
        }

        const { isSmartGoals } = currentModule;
        let tickTock = <IconClock />;
        if (currentModule.time === undefined) {
            tickTock = null;
        }
        return (
            <div className={`${style.overview} ${isSmartGoals ? style.isSmartGoals : ''}`}>
                <h5>{currentModule.overviewTitle || translate[language].overview}</h5>
                <div className={style.overviewContent}>
                    <Html content={currentModule.overview} />
                </div>
                <div className={style.timeClock}>
                    {tickTock}
                    &nbsp;
                    {currentModule.time}
                </div>
                {isSmartGoals && Object.values(mostRecentSmartGoals).some(goal => goal) && (
                    <div className={style.smartReportButtonRow}>
                        <Modal
                            trigger={
                                <button type="button" className="btn btn-default">
                                    <ReportIcon />
                                    &nbsp;&nbsp;Report
                                </button>
                            }
                            size="large"
                            title="Smart Report"
                        >
                            <ReportRender handle="smartReport" mostRecentSmartGoals={mostRecentSmartGoals} />
                        </Modal>
                    </div>
                )}
            </div>
        );
    }

    function renderLessonArea() {
        return (
            <div className={style.lessonWrapper}>
                {renderOverview()}
                <div className={style.lessonArea}>
                    {currentModule.lessons.map((item, index) => (
                        <LessonCard
                            key={index}
                            item={item}
                            language={language}
                            lessonNum={index}
                            lessons={currentModule.lessons}
                            mostRecentSmartGoals={mostRecentSmartGoals}
                            mostRecentPoiseData={mostRecentPoiseData}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <DefaultPlayerSettingsContext.Provider
            value={{
                background: 'solidColor',
                backgroundColor: '#fff',
                height: '600px',
            }}
        >
            <Loader loading={props.isLoading || isLoading} removeChildren={!isNetworkError}>
                {renderPlayerView()}
                {renderModuleView()}
            </Loader>
        </DefaultPlayerSettingsContext.Provider>
    );
};

export default PoiseWithModulesMediator;
