import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../widgets.js';

export const initialValues = {
    title: 'POISE Well-Being Self-Assessment Scale',
    filterFields: [],
    otherFilterOptions: [],
    hideFilterBar: true,
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = () => {
    return (
        <BuilderBlock title="Header">
            <Field name="title" component={CccisdInput} label="Title" />
        </BuilderBlock>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({ widgets })(Component);
