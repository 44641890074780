import React from 'react';
import LockIcon from 'cccisd-icons/lock4';

const Tab = ({ currTab, mediatorProps, onClickTab, tabKey }) => {
    let locked = false;
    if (!mediatorProps[tabKey]) {
        tabKey = tabKey.replace(/_locked$/, '');
        if (!mediatorProps[tabKey]) {
            return null;
        }
        locked = true;
    }
    return !mediatorProps[tabKey].toggle ? null : (
        <li role="presentation" className={`${currTab === tabKey ? 'active' : null}`}>
            <a
                onClick={() => {
                    if (!locked) {
                        onClickTab(tabKey);
                    }
                }}
            >
                {mediatorProps[tabKey].tabName}
                {locked ? (
                    <span>
                        &nbsp;&nbsp;
                        <LockIcon />
                    </span>
                ) : null}
            </a>
        </li>
    );
};

export default Tab;
