import React from 'react';
import PropTypes from 'prop-types';
import { Report } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import style from './style.css';
import HeaderLogo from './images/WISE_Logo.png';
import FooterLogo from './images/Footer_Logo.png';
import format from 'date-fns/format';
import ReportTable from './ReportTable';

const Component = props => {
    const { title } = props.settings;
    const date = format(new Date(), 'MM-dd-yyyy');
    const year = format(new Date(), 'yyyy');
    const renderHeader = () => {
        return (
            <>
                <div className={style.reportHeader}>
                    <img src={HeaderLogo} alt="headerLogo" width="300px" height="62px" />
                    <div className={style.title}>{title}</div>
                    <div className={style.date}>{date}</div>
                </div>
                <hr />
            </>
        );
    };
    const renderFooter = () => {
        return (
            <>
                <hr />
                <footer className={style.reportFooter}>
                    <div className={style.footerText}>
                        &copy; {year}{' '}
                        <span style={{ fontSize: '14px' }}>Medstar Georgetown WISE Center</span>
                    </div>
                    <div className={style.footerLogo}>
                        <img src={FooterLogo} alt="footerLogo" width="180px" height="36px" />
                    </div>
                </footer>
            </>
        );
    };

    const now = new Date();
    return (
        <Report
            downloadFilename={`SMART_${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}`}
        >
            <ReportTable props={props} renderFooter={renderFooter} renderHeader={renderHeader} />
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    filterFields: [],
})(Component);
